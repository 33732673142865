.TracesReviewCine-styles-module--container--2jTl0 {
  position: relative; }

.TracesReviewCine-styles-module--selected-info--24seX {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem; }

.TracesReviewCine-styles-module--dropdown--BqfWW {
  min-width: 30rem; }
