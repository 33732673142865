.TagButton-styles-module--btn--2ZFJA {
  justify-content: flex-start;
  min-height: 3rem;
  border: 1px solid transparent;
  border-radius: 5px;
  white-space: initial;
  text-align: left;
  padding: 0 2rem 0 1rem; }

.TagButton-styles-module--active--1VO-k {
  border: 1px solid var(--theme-link-color, white); }
