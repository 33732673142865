.Page-styles-module--container--1B4-o {
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0; }
  .Page-styles-module--container--1B4-o.Page-styles-module--center--2w-9M {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh; }
  .Navbar-styles-module--navbar--1GEVz ~ .Page-styles-module--container--1B4-o {
    top: 6rem; }
    .Navbar-styles-module--navbar--1GEVz ~ .Page-styles-module--container--1B4-o.Page-styles-module--center--2w-9M {
      min-height: calc(100vh - 6rem);
      padding-bottom: 6rem; }
  .AppBanner-module--cls1--PR-EM ~ .Page-styles-module--container--1B4-o {
    top: 9rem; }
    .AppBanner-module--cls1--PR-EM ~ .Page-styles-module--container--1B4-o.Page-styles-module--center--2w-9M {
      min-height: calc(100vh - 9rem);
      padding-bottom: 9rem; }
  .fill-viewport .Page-styles-module--container--1B4-o {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0; }

.Page-styles-module--side-panel--3am8v {
  position: fixed;
  z-index: 980;
  top: 6rem;
  bottom: 0;
  width: 26rem;
  padding-bottom: 4rem;
  background: #1a1a1a; }

.Page-styles-module--side-panel-drawer--2ai8G {
  box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.3); }

.Page-styles-module--side-panel-static--1nXUe {
  border-right: 1px solid #262626; }
  .AppBanner-module--cls1--PR-EM ~ .Page-styles-module--container--1B4-o > .Page-styles-module--side-panel-static--1nXUe {
    top: 9rem; }

.Page-styles-module--main--36jql {
  padding-left: 0; }
  .Page-styles-module--side-panel-static--1nXUe + .Page-styles-module--main--36jql {
    padding-left: 26rem; }
    .fill-viewport .Page-styles-module--side-panel-static--1nXUe + .Page-styles-module--main--36jql {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 26rem;
      padding-left: 0; }

.Page-styles-module--sticky--3glmI {
  position: -webkit-sticky;
  position: sticky;
  top: 6rem;
  z-index: 1030; }
  .AppBanner-module--cls1--PR-EM ~ .Page-styles-module--container--1B4-o .Page-styles-module--sticky--3glmI {
    top: 9rem; }
