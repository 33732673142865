.TaskReferenceImagePage-ReferenceImage-module--cls1--kld2- {
  /*!*/ }

.TaskReferenceImagePage-ReferenceImage-module--cls2--3cIIB {
  display: block;
  width: 14rem;
  height: 14rem;
  padding: 0.2rem;
  background-color: black;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #808080;
  cursor: pointer; }
  .TaskReferenceImagePage-ReferenceImage-module--cls2--3cIIB:hover, .TaskReferenceImagePage-ReferenceImage-module--cls2--3cIIB:focus {
    border-color: #2779ff; }
