@keyframes FrameScrubber-styles-module--stripes--3-SJx {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.FrameScrubber-styles-module--container--obC74 {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 2rem;
  margin: 1rem 0;
  background: #ddd; }

.FrameScrubber-styles-module--large--3Ygud {
  min-height: 6rem; }

.FrameScrubber-styles-module--interval--1DOgc {
  position: absolute;
  top: 0;
  bottom: 0;
  pointer-events: none; }

.FrameScrubber-styles-module--segment--3XeWz {
  position: relative;
  min-height: 1rem;
  flex: 1 1 0;
  height: 100%;
  z-index: 2; }

.FrameScrubber-styles-module--indicator--37FIJ {
  box-sizing: content-box;
  border: 2px solid #333;
  margin: -2px;
  z-index: 2; }

.FrameScrubber-styles-module--hover--2jIFU {
  background: rgba(0, 0, 0, 0.3);
  z-index: 2; }

.FrameScrubber-styles-module--range--28chm {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }
  .FrameScrubber-styles-module--range--28chm.FrameScrubber-styles-module--selected--od88t {
    animation: FrameScrubber-styles-module--stripes--3-SJx 0.5s linear infinite; }

.FrameScrubber-styles-module--disabled--1lmL9 {
  cursor: not-allowed;
  background-color: #b75555; }

.FrameScrubber-styles-module--enabled-frame--12IR9 {
  background: #ddd;
  transform: scaleY(1.2);
  pointer-events: auto;
  cursor: pointer;
  transition: transform 200ms; }
  .FrameScrubber-styles-module--enabled-frame--12IR9:hover {
    transform: scale(1.5, 1.5); }
