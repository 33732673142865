.TracesReview-Sidebar-module--cls1--1iy8i {
  /*!*/ }

.TracesReview-Sidebar-module--cls2--IE2jx {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  bottom: 0;
  padding: 1.6rem;
  width: 30rem; }
