.CineReview-styles-module--cine--8rtCX {
  position: -webkit-sticky;
  position: sticky;
  top: 5rem; }

.CineReview-styles-module--head--juRFw {
  top: 6rem;
  padding: 0.5rem 1rem;
  background-color: #1a1a1a;
  z-index: 1; }

.CineReview-styles-module--action--3UkHL {
  flex: 1 1 0; }

.CineReview-styles-module--username--1tKah {
  flex: 1.5 1 0; }

.CineReview-styles-module--annotation--1qRzo {
  flex: 3 1 0; }

.CineReview-styles-module--created-at--2kQa4 {
  flex: 1.5 1 0; }
