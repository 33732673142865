/* 300 Regular */
@font-face {
  font-family: 'Biotif';
  src: url(/Biotif-Regular-7ae9923b67c36d14746855c41d062c33.woff);
  font-weight: 300; }

/* 700 Bold */
@font-face {
  font-family: 'Biotif';
  src: url(/Biotif-Bold-cf785147b70894795d8be15b3f24593b.woff);
  font-weight: 700; }

/* 800 ExtraBold */
@font-face {
  font-family: 'Biotif';
  src: url(/Biotif-ExtraBold-6b36f86b40a4937991090edf791f74f6.woff);
  font-weight: 800; }

/*
Bootstrap includes have to be defined prior to
@bfly/ui so that our lib can override values.
*/
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #ffffff;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #333333; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #ffffff;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 5px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: transparent; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 1.05rem;
  background-color: #e9ecef;
  border-radius: 5px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #2779ff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Biotif, Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  font-weight: 300;
  line-height: 1.42857;
  color: #bfbfbf;
  text-align: left;
  background-color: #1a1a1a; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.6rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #ffffff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #bfbfbf;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 24.5rem;
  padding: 0.5rem 0;
  margin: 0.2rem 0 0;
  font-size: 1.4rem;
  color: #bfbfbf;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0 solid #ffffff;
  border-radius: 5px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.2rem; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.2rem; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.2rem; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.6rem 0;
  overflow: hidden;
  border-top: 1px solid #f2f2f2; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 1rem 2rem;
  clear: both;
  font-weight: 300;
  color: #1a1a1a;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #f2f2f2; }
  .dropdown-item.active, .dropdown-item:active {
    color: #1a1a1a;
    text-decoration: none;
    background-color: #dedddd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 2rem;
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 1rem 2rem;
  color: #1a1a1a; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #ffffff;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #333333; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .nav-tabs .nav-link.disabled {
      color: #ffffff;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: transparent;
    border-color: #dee2e6 #dee2e6 transparent; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 5px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: transparent; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 5px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.6rem; }

.card-title {
  margin-bottom: 1.6rem; }

.card-subtitle {
  margin-top: -0.8rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.6rem; }

.card-header {
  padding: 1.6rem 1.6rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid transparent; }
  .card-header:first-child {
    border-radius: 4px 4px 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.6rem 1.6rem;
  background-color: transparent;
  border-top: 1px solid transparent; }
  .card-footer:last-child {
    border-radius: 0 0 4px 4px; }

.card-header-tabs {
  margin-right: -0.8rem;
  margin-bottom: -1.6rem;
  margin-left: -0.8rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.8rem;
  margin-left: -0.8rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.6rem; }

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 1.25rem;
         column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.close {
  float: right;
  font-size: 2.1rem;
  font-weight: 700;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #000000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 1; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px; }
  .modal-header .close {
    padding: 2rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.42857; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px; }
  .modal-footer > * {
    margin: 0; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 80rem;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.4rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.4rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.4rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.4rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.4rem !important; }

.m-2 {
  margin: 0.8rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.8rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.8rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.8rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.8rem !important; }

.m-3 {
  margin: 1.2rem !important; }

.mt-3,
.my-3 {
  margin-top: 1.2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1.2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1.2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1.2rem !important; }

.m-4 {
  margin: 1.6rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.6rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.6rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.6rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.6rem !important; }

.m-5 {
  margin: 2rem !important; }

.mt-5,
.my-5 {
  margin-top: 2rem !important; }

.mr-5,
.mx-5 {
  margin-right: 2rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 2rem !important; }

.ml-5,
.mx-5 {
  margin-left: 2rem !important; }

.m-px {
  margin: 1px !important; }

.mt-px,
.my-px {
  margin-top: 1px !important; }

.mr-px,
.mx-px {
  margin-right: 1px !important; }

.mb-px,
.my-px {
  margin-bottom: 1px !important; }

.ml-px,
.mx-px {
  margin-left: 1px !important; }

.m-6 {
  margin: 2.4rem !important; }

.mt-6,
.my-6 {
  margin-top: 2.4rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2.4rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2.4rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2.4rem !important; }

.m-8 {
  margin: 3.2rem !important; }

.mt-8,
.my-8 {
  margin-top: 3.2rem !important; }

.mr-8,
.mx-8 {
  margin-right: 3.2rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 3.2rem !important; }

.ml-8,
.mx-8 {
  margin-left: 3.2rem !important; }

.m-10 {
  margin: 4rem !important; }

.mt-10,
.my-10 {
  margin-top: 4rem !important; }

.mr-10,
.mx-10 {
  margin-right: 4rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 4rem !important; }

.ml-10,
.mx-10 {
  margin-left: 4rem !important; }

.m-12 {
  margin: 4.8rem !important; }

.mt-12,
.my-12 {
  margin-top: 4.8rem !important; }

.mr-12,
.mx-12 {
  margin-right: 4.8rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 4.8rem !important; }

.ml-12,
.mx-12 {
  margin-left: 4.8rem !important; }

.m-16 {
  margin: 6.4rem !important; }

.mt-16,
.my-16 {
  margin-top: 6.4rem !important; }

.mr-16,
.mx-16 {
  margin-right: 6.4rem !important; }

.mb-16,
.my-16 {
  margin-bottom: 6.4rem !important; }

.ml-16,
.mx-16 {
  margin-left: 6.4rem !important; }

.m-20 {
  margin: 8rem !important; }

.mt-20,
.my-20 {
  margin-top: 8rem !important; }

.mr-20,
.mx-20 {
  margin-right: 8rem !important; }

.mb-20,
.my-20 {
  margin-bottom: 8rem !important; }

.ml-20,
.mx-20 {
  margin-left: 8rem !important; }

.m-24 {
  margin: 9.6rem !important; }

.mt-24,
.my-24 {
  margin-top: 9.6rem !important; }

.mr-24,
.mx-24 {
  margin-right: 9.6rem !important; }

.mb-24,
.my-24 {
  margin-bottom: 9.6rem !important; }

.ml-24,
.mx-24 {
  margin-left: 9.6rem !important; }

.m-32 {
  margin: 12.8rem !important; }

.mt-32,
.my-32 {
  margin-top: 12.8rem !important; }

.mr-32,
.mx-32 {
  margin-right: 12.8rem !important; }

.mb-32,
.my-32 {
  margin-bottom: 12.8rem !important; }

.ml-32,
.mx-32 {
  margin-left: 12.8rem !important; }

.m-40 {
  margin: 16rem !important; }

.mt-40,
.my-40 {
  margin-top: 16rem !important; }

.mr-40,
.mx-40 {
  margin-right: 16rem !important; }

.mb-40,
.my-40 {
  margin-bottom: 16rem !important; }

.ml-40,
.mx-40 {
  margin-left: 16rem !important; }

.m-48 {
  margin: 19.2rem !important; }

.mt-48,
.my-48 {
  margin-top: 19.2rem !important; }

.mr-48,
.mx-48 {
  margin-right: 19.2rem !important; }

.mb-48,
.my-48 {
  margin-bottom: 19.2rem !important; }

.ml-48,
.mx-48 {
  margin-left: 19.2rem !important; }

.m-56 {
  margin: 22.4rem !important; }

.mt-56,
.my-56 {
  margin-top: 22.4rem !important; }

.mr-56,
.mx-56 {
  margin-right: 22.4rem !important; }

.mb-56,
.my-56 {
  margin-bottom: 22.4rem !important; }

.ml-56,
.mx-56 {
  margin-left: 22.4rem !important; }

.m-64 {
  margin: 25.6rem !important; }

.mt-64,
.my-64 {
  margin-top: 25.6rem !important; }

.mr-64,
.mx-64 {
  margin-right: 25.6rem !important; }

.mb-64,
.my-64 {
  margin-bottom: 25.6rem !important; }

.ml-64,
.mx-64 {
  margin-left: 25.6rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.4rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.4rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.4rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.4rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.4rem !important; }

.p-2 {
  padding: 0.8rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.8rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.8rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.8rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.8rem !important; }

.p-3 {
  padding: 1.2rem !important; }

.pt-3,
.py-3 {
  padding-top: 1.2rem !important; }

.pr-3,
.px-3 {
  padding-right: 1.2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1.2rem !important; }

.pl-3,
.px-3 {
  padding-left: 1.2rem !important; }

.p-4 {
  padding: 1.6rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.6rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.6rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.6rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.6rem !important; }

.p-5 {
  padding: 2rem !important; }

.pt-5,
.py-5 {
  padding-top: 2rem !important; }

.pr-5,
.px-5 {
  padding-right: 2rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 2rem !important; }

.pl-5,
.px-5 {
  padding-left: 2rem !important; }

.p-px {
  padding: 1px !important; }

.pt-px,
.py-px {
  padding-top: 1px !important; }

.pr-px,
.px-px {
  padding-right: 1px !important; }

.pb-px,
.py-px {
  padding-bottom: 1px !important; }

.pl-px,
.px-px {
  padding-left: 1px !important; }

.p-6 {
  padding: 2.4rem !important; }

.pt-6,
.py-6 {
  padding-top: 2.4rem !important; }

.pr-6,
.px-6 {
  padding-right: 2.4rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2.4rem !important; }

.pl-6,
.px-6 {
  padding-left: 2.4rem !important; }

.p-8 {
  padding: 3.2rem !important; }

.pt-8,
.py-8 {
  padding-top: 3.2rem !important; }

.pr-8,
.px-8 {
  padding-right: 3.2rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 3.2rem !important; }

.pl-8,
.px-8 {
  padding-left: 3.2rem !important; }

.p-10 {
  padding: 4rem !important; }

.pt-10,
.py-10 {
  padding-top: 4rem !important; }

.pr-10,
.px-10 {
  padding-right: 4rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 4rem !important; }

.pl-10,
.px-10 {
  padding-left: 4rem !important; }

.p-12 {
  padding: 4.8rem !important; }

.pt-12,
.py-12 {
  padding-top: 4.8rem !important; }

.pr-12,
.px-12 {
  padding-right: 4.8rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 4.8rem !important; }

.pl-12,
.px-12 {
  padding-left: 4.8rem !important; }

.p-16 {
  padding: 6.4rem !important; }

.pt-16,
.py-16 {
  padding-top: 6.4rem !important; }

.pr-16,
.px-16 {
  padding-right: 6.4rem !important; }

.pb-16,
.py-16 {
  padding-bottom: 6.4rem !important; }

.pl-16,
.px-16 {
  padding-left: 6.4rem !important; }

.p-20 {
  padding: 8rem !important; }

.pt-20,
.py-20 {
  padding-top: 8rem !important; }

.pr-20,
.px-20 {
  padding-right: 8rem !important; }

.pb-20,
.py-20 {
  padding-bottom: 8rem !important; }

.pl-20,
.px-20 {
  padding-left: 8rem !important; }

.p-24 {
  padding: 9.6rem !important; }

.pt-24,
.py-24 {
  padding-top: 9.6rem !important; }

.pr-24,
.px-24 {
  padding-right: 9.6rem !important; }

.pb-24,
.py-24 {
  padding-bottom: 9.6rem !important; }

.pl-24,
.px-24 {
  padding-left: 9.6rem !important; }

.p-32 {
  padding: 12.8rem !important; }

.pt-32,
.py-32 {
  padding-top: 12.8rem !important; }

.pr-32,
.px-32 {
  padding-right: 12.8rem !important; }

.pb-32,
.py-32 {
  padding-bottom: 12.8rem !important; }

.pl-32,
.px-32 {
  padding-left: 12.8rem !important; }

.p-40 {
  padding: 16rem !important; }

.pt-40,
.py-40 {
  padding-top: 16rem !important; }

.pr-40,
.px-40 {
  padding-right: 16rem !important; }

.pb-40,
.py-40 {
  padding-bottom: 16rem !important; }

.pl-40,
.px-40 {
  padding-left: 16rem !important; }

.p-48 {
  padding: 19.2rem !important; }

.pt-48,
.py-48 {
  padding-top: 19.2rem !important; }

.pr-48,
.px-48 {
  padding-right: 19.2rem !important; }

.pb-48,
.py-48 {
  padding-bottom: 19.2rem !important; }

.pl-48,
.px-48 {
  padding-left: 19.2rem !important; }

.p-56 {
  padding: 22.4rem !important; }

.pt-56,
.py-56 {
  padding-top: 22.4rem !important; }

.pr-56,
.px-56 {
  padding-right: 22.4rem !important; }

.pb-56,
.py-56 {
  padding-bottom: 22.4rem !important; }

.pl-56,
.px-56 {
  padding-left: 22.4rem !important; }

.p-64 {
  padding: 25.6rem !important; }

.pt-64,
.py-64 {
  padding-top: 25.6rem !important; }

.pr-64,
.px-64 {
  padding-right: 25.6rem !important; }

.pb-64,
.py-64 {
  padding-bottom: 25.6rem !important; }

.pl-64,
.px-64 {
  padding-left: 25.6rem !important; }

.m-n1 {
  margin: -0.4rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.4rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.4rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.4rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.4rem !important; }

.m-n2 {
  margin: -0.8rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.8rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.8rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.8rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.8rem !important; }

.m-n3 {
  margin: -1.2rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1.2rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1.2rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1.2rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1.2rem !important; }

.m-n4 {
  margin: -1.6rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.6rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.6rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.6rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.6rem !important; }

.m-n5 {
  margin: -2rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -2rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -2rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -2rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -2rem !important; }

.m-npx {
  margin: -1px !important; }

.mt-npx,
.my-npx {
  margin-top: -1px !important; }

.mr-npx,
.mx-npx {
  margin-right: -1px !important; }

.mb-npx,
.my-npx {
  margin-bottom: -1px !important; }

.ml-npx,
.mx-npx {
  margin-left: -1px !important; }

.m-n6 {
  margin: -2.4rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -2.4rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -2.4rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -2.4rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -2.4rem !important; }

.m-n8 {
  margin: -3.2rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -3.2rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -3.2rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -3.2rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -3.2rem !important; }

.m-n10 {
  margin: -4rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -4rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -4rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -4rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -4rem !important; }

.m-n12 {
  margin: -4.8rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -4.8rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -4.8rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -4.8rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -4.8rem !important; }

.m-n16 {
  margin: -6.4rem !important; }

.mt-n16,
.my-n16 {
  margin-top: -6.4rem !important; }

.mr-n16,
.mx-n16 {
  margin-right: -6.4rem !important; }

.mb-n16,
.my-n16 {
  margin-bottom: -6.4rem !important; }

.ml-n16,
.mx-n16 {
  margin-left: -6.4rem !important; }

.m-n20 {
  margin: -8rem !important; }

.mt-n20,
.my-n20 {
  margin-top: -8rem !important; }

.mr-n20,
.mx-n20 {
  margin-right: -8rem !important; }

.mb-n20,
.my-n20 {
  margin-bottom: -8rem !important; }

.ml-n20,
.mx-n20 {
  margin-left: -8rem !important; }

.m-n24 {
  margin: -9.6rem !important; }

.mt-n24,
.my-n24 {
  margin-top: -9.6rem !important; }

.mr-n24,
.mx-n24 {
  margin-right: -9.6rem !important; }

.mb-n24,
.my-n24 {
  margin-bottom: -9.6rem !important; }

.ml-n24,
.mx-n24 {
  margin-left: -9.6rem !important; }

.m-n32 {
  margin: -12.8rem !important; }

.mt-n32,
.my-n32 {
  margin-top: -12.8rem !important; }

.mr-n32,
.mx-n32 {
  margin-right: -12.8rem !important; }

.mb-n32,
.my-n32 {
  margin-bottom: -12.8rem !important; }

.ml-n32,
.mx-n32 {
  margin-left: -12.8rem !important; }

.m-n40 {
  margin: -16rem !important; }

.mt-n40,
.my-n40 {
  margin-top: -16rem !important; }

.mr-n40,
.mx-n40 {
  margin-right: -16rem !important; }

.mb-n40,
.my-n40 {
  margin-bottom: -16rem !important; }

.ml-n40,
.mx-n40 {
  margin-left: -16rem !important; }

.m-n48 {
  margin: -19.2rem !important; }

.mt-n48,
.my-n48 {
  margin-top: -19.2rem !important; }

.mr-n48,
.mx-n48 {
  margin-right: -19.2rem !important; }

.mb-n48,
.my-n48 {
  margin-bottom: -19.2rem !important; }

.ml-n48,
.mx-n48 {
  margin-left: -19.2rem !important; }

.m-n56 {
  margin: -22.4rem !important; }

.mt-n56,
.my-n56 {
  margin-top: -22.4rem !important; }

.mr-n56,
.mx-n56 {
  margin-right: -22.4rem !important; }

.mb-n56,
.my-n56 {
  margin-bottom: -22.4rem !important; }

.ml-n56,
.mx-n56 {
  margin-left: -22.4rem !important; }

.m-n64 {
  margin: -25.6rem !important; }

.mt-n64,
.my-n64 {
  margin-top: -25.6rem !important; }

.mr-n64,
.mx-n64 {
  margin-right: -25.6rem !important; }

.mb-n64,
.my-n64 {
  margin-bottom: -25.6rem !important; }

.ml-n64,
.mx-n64 {
  margin-left: -25.6rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.4rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.4rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.4rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.4rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.4rem !important; }
  .m-sm-2 {
    margin: 0.8rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.8rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.8rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.8rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.8rem !important; }
  .m-sm-3 {
    margin: 1.2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.2rem !important; }
  .m-sm-4 {
    margin: 1.6rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.6rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.6rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.6rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.6rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2rem !important; }
  .m-sm-px {
    margin: 1px !important; }
  .mt-sm-px,
  .my-sm-px {
    margin-top: 1px !important; }
  .mr-sm-px,
  .mx-sm-px {
    margin-right: 1px !important; }
  .mb-sm-px,
  .my-sm-px {
    margin-bottom: 1px !important; }
  .ml-sm-px,
  .mx-sm-px {
    margin-left: 1px !important; }
  .m-sm-6 {
    margin: 2.4rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2.4rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2.4rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2.4rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2.4rem !important; }
  .m-sm-8 {
    margin: 3.2rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.2rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.2rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.2rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.2rem !important; }
  .m-sm-10 {
    margin: 4rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 4rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 4rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 4rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 4rem !important; }
  .m-sm-12 {
    margin: 4.8rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 4.8rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 4.8rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 4.8rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 4.8rem !important; }
  .m-sm-16 {
    margin: 6.4rem !important; }
  .mt-sm-16,
  .my-sm-16 {
    margin-top: 6.4rem !important; }
  .mr-sm-16,
  .mx-sm-16 {
    margin-right: 6.4rem !important; }
  .mb-sm-16,
  .my-sm-16 {
    margin-bottom: 6.4rem !important; }
  .ml-sm-16,
  .mx-sm-16 {
    margin-left: 6.4rem !important; }
  .m-sm-20 {
    margin: 8rem !important; }
  .mt-sm-20,
  .my-sm-20 {
    margin-top: 8rem !important; }
  .mr-sm-20,
  .mx-sm-20 {
    margin-right: 8rem !important; }
  .mb-sm-20,
  .my-sm-20 {
    margin-bottom: 8rem !important; }
  .ml-sm-20,
  .mx-sm-20 {
    margin-left: 8rem !important; }
  .m-sm-24 {
    margin: 9.6rem !important; }
  .mt-sm-24,
  .my-sm-24 {
    margin-top: 9.6rem !important; }
  .mr-sm-24,
  .mx-sm-24 {
    margin-right: 9.6rem !important; }
  .mb-sm-24,
  .my-sm-24 {
    margin-bottom: 9.6rem !important; }
  .ml-sm-24,
  .mx-sm-24 {
    margin-left: 9.6rem !important; }
  .m-sm-32 {
    margin: 12.8rem !important; }
  .mt-sm-32,
  .my-sm-32 {
    margin-top: 12.8rem !important; }
  .mr-sm-32,
  .mx-sm-32 {
    margin-right: 12.8rem !important; }
  .mb-sm-32,
  .my-sm-32 {
    margin-bottom: 12.8rem !important; }
  .ml-sm-32,
  .mx-sm-32 {
    margin-left: 12.8rem !important; }
  .m-sm-40 {
    margin: 16rem !important; }
  .mt-sm-40,
  .my-sm-40 {
    margin-top: 16rem !important; }
  .mr-sm-40,
  .mx-sm-40 {
    margin-right: 16rem !important; }
  .mb-sm-40,
  .my-sm-40 {
    margin-bottom: 16rem !important; }
  .ml-sm-40,
  .mx-sm-40 {
    margin-left: 16rem !important; }
  .m-sm-48 {
    margin: 19.2rem !important; }
  .mt-sm-48,
  .my-sm-48 {
    margin-top: 19.2rem !important; }
  .mr-sm-48,
  .mx-sm-48 {
    margin-right: 19.2rem !important; }
  .mb-sm-48,
  .my-sm-48 {
    margin-bottom: 19.2rem !important; }
  .ml-sm-48,
  .mx-sm-48 {
    margin-left: 19.2rem !important; }
  .m-sm-56 {
    margin: 22.4rem !important; }
  .mt-sm-56,
  .my-sm-56 {
    margin-top: 22.4rem !important; }
  .mr-sm-56,
  .mx-sm-56 {
    margin-right: 22.4rem !important; }
  .mb-sm-56,
  .my-sm-56 {
    margin-bottom: 22.4rem !important; }
  .ml-sm-56,
  .mx-sm-56 {
    margin-left: 22.4rem !important; }
  .m-sm-64 {
    margin: 25.6rem !important; }
  .mt-sm-64,
  .my-sm-64 {
    margin-top: 25.6rem !important; }
  .mr-sm-64,
  .mx-sm-64 {
    margin-right: 25.6rem !important; }
  .mb-sm-64,
  .my-sm-64 {
    margin-bottom: 25.6rem !important; }
  .ml-sm-64,
  .mx-sm-64 {
    margin-left: 25.6rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.4rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.4rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.4rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.4rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.4rem !important; }
  .p-sm-2 {
    padding: 0.8rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.8rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.8rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.8rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.8rem !important; }
  .p-sm-3 {
    padding: 1.2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.2rem !important; }
  .p-sm-4 {
    padding: 1.6rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.6rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.6rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.6rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.6rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2rem !important; }
  .p-sm-px {
    padding: 1px !important; }
  .pt-sm-px,
  .py-sm-px {
    padding-top: 1px !important; }
  .pr-sm-px,
  .px-sm-px {
    padding-right: 1px !important; }
  .pb-sm-px,
  .py-sm-px {
    padding-bottom: 1px !important; }
  .pl-sm-px,
  .px-sm-px {
    padding-left: 1px !important; }
  .p-sm-6 {
    padding: 2.4rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2.4rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2.4rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2.4rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2.4rem !important; }
  .p-sm-8 {
    padding: 3.2rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.2rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.2rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.2rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.2rem !important; }
  .p-sm-10 {
    padding: 4rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 4rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 4rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 4rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 4rem !important; }
  .p-sm-12 {
    padding: 4.8rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 4.8rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 4.8rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 4.8rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 4.8rem !important; }
  .p-sm-16 {
    padding: 6.4rem !important; }
  .pt-sm-16,
  .py-sm-16 {
    padding-top: 6.4rem !important; }
  .pr-sm-16,
  .px-sm-16 {
    padding-right: 6.4rem !important; }
  .pb-sm-16,
  .py-sm-16 {
    padding-bottom: 6.4rem !important; }
  .pl-sm-16,
  .px-sm-16 {
    padding-left: 6.4rem !important; }
  .p-sm-20 {
    padding: 8rem !important; }
  .pt-sm-20,
  .py-sm-20 {
    padding-top: 8rem !important; }
  .pr-sm-20,
  .px-sm-20 {
    padding-right: 8rem !important; }
  .pb-sm-20,
  .py-sm-20 {
    padding-bottom: 8rem !important; }
  .pl-sm-20,
  .px-sm-20 {
    padding-left: 8rem !important; }
  .p-sm-24 {
    padding: 9.6rem !important; }
  .pt-sm-24,
  .py-sm-24 {
    padding-top: 9.6rem !important; }
  .pr-sm-24,
  .px-sm-24 {
    padding-right: 9.6rem !important; }
  .pb-sm-24,
  .py-sm-24 {
    padding-bottom: 9.6rem !important; }
  .pl-sm-24,
  .px-sm-24 {
    padding-left: 9.6rem !important; }
  .p-sm-32 {
    padding: 12.8rem !important; }
  .pt-sm-32,
  .py-sm-32 {
    padding-top: 12.8rem !important; }
  .pr-sm-32,
  .px-sm-32 {
    padding-right: 12.8rem !important; }
  .pb-sm-32,
  .py-sm-32 {
    padding-bottom: 12.8rem !important; }
  .pl-sm-32,
  .px-sm-32 {
    padding-left: 12.8rem !important; }
  .p-sm-40 {
    padding: 16rem !important; }
  .pt-sm-40,
  .py-sm-40 {
    padding-top: 16rem !important; }
  .pr-sm-40,
  .px-sm-40 {
    padding-right: 16rem !important; }
  .pb-sm-40,
  .py-sm-40 {
    padding-bottom: 16rem !important; }
  .pl-sm-40,
  .px-sm-40 {
    padding-left: 16rem !important; }
  .p-sm-48 {
    padding: 19.2rem !important; }
  .pt-sm-48,
  .py-sm-48 {
    padding-top: 19.2rem !important; }
  .pr-sm-48,
  .px-sm-48 {
    padding-right: 19.2rem !important; }
  .pb-sm-48,
  .py-sm-48 {
    padding-bottom: 19.2rem !important; }
  .pl-sm-48,
  .px-sm-48 {
    padding-left: 19.2rem !important; }
  .p-sm-56 {
    padding: 22.4rem !important; }
  .pt-sm-56,
  .py-sm-56 {
    padding-top: 22.4rem !important; }
  .pr-sm-56,
  .px-sm-56 {
    padding-right: 22.4rem !important; }
  .pb-sm-56,
  .py-sm-56 {
    padding-bottom: 22.4rem !important; }
  .pl-sm-56,
  .px-sm-56 {
    padding-left: 22.4rem !important; }
  .p-sm-64 {
    padding: 25.6rem !important; }
  .pt-sm-64,
  .py-sm-64 {
    padding-top: 25.6rem !important; }
  .pr-sm-64,
  .px-sm-64 {
    padding-right: 25.6rem !important; }
  .pb-sm-64,
  .py-sm-64 {
    padding-bottom: 25.6rem !important; }
  .pl-sm-64,
  .px-sm-64 {
    padding-left: 25.6rem !important; }
  .m-sm-n1 {
    margin: -0.4rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.4rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.4rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.4rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.4rem !important; }
  .m-sm-n2 {
    margin: -0.8rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.8rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.8rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.8rem !important; }
  .m-sm-n3 {
    margin: -1.2rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.2rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.2rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.2rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.2rem !important; }
  .m-sm-n4 {
    margin: -1.6rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.6rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.6rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.6rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.6rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2rem !important; }
  .m-sm-npx {
    margin: -1px !important; }
  .mt-sm-npx,
  .my-sm-npx {
    margin-top: -1px !important; }
  .mr-sm-npx,
  .mx-sm-npx {
    margin-right: -1px !important; }
  .mb-sm-npx,
  .my-sm-npx {
    margin-bottom: -1px !important; }
  .ml-sm-npx,
  .mx-sm-npx {
    margin-left: -1px !important; }
  .m-sm-n6 {
    margin: -2.4rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2.4rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2.4rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2.4rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2.4rem !important; }
  .m-sm-n8 {
    margin: -3.2rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.2rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.2rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.2rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.2rem !important; }
  .m-sm-n10 {
    margin: -4rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -4rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -4rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -4rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -4rem !important; }
  .m-sm-n12 {
    margin: -4.8rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -4.8rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -4.8rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -4.8rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -4.8rem !important; }
  .m-sm-n16 {
    margin: -6.4rem !important; }
  .mt-sm-n16,
  .my-sm-n16 {
    margin-top: -6.4rem !important; }
  .mr-sm-n16,
  .mx-sm-n16 {
    margin-right: -6.4rem !important; }
  .mb-sm-n16,
  .my-sm-n16 {
    margin-bottom: -6.4rem !important; }
  .ml-sm-n16,
  .mx-sm-n16 {
    margin-left: -6.4rem !important; }
  .m-sm-n20 {
    margin: -8rem !important; }
  .mt-sm-n20,
  .my-sm-n20 {
    margin-top: -8rem !important; }
  .mr-sm-n20,
  .mx-sm-n20 {
    margin-right: -8rem !important; }
  .mb-sm-n20,
  .my-sm-n20 {
    margin-bottom: -8rem !important; }
  .ml-sm-n20,
  .mx-sm-n20 {
    margin-left: -8rem !important; }
  .m-sm-n24 {
    margin: -9.6rem !important; }
  .mt-sm-n24,
  .my-sm-n24 {
    margin-top: -9.6rem !important; }
  .mr-sm-n24,
  .mx-sm-n24 {
    margin-right: -9.6rem !important; }
  .mb-sm-n24,
  .my-sm-n24 {
    margin-bottom: -9.6rem !important; }
  .ml-sm-n24,
  .mx-sm-n24 {
    margin-left: -9.6rem !important; }
  .m-sm-n32 {
    margin: -12.8rem !important; }
  .mt-sm-n32,
  .my-sm-n32 {
    margin-top: -12.8rem !important; }
  .mr-sm-n32,
  .mx-sm-n32 {
    margin-right: -12.8rem !important; }
  .mb-sm-n32,
  .my-sm-n32 {
    margin-bottom: -12.8rem !important; }
  .ml-sm-n32,
  .mx-sm-n32 {
    margin-left: -12.8rem !important; }
  .m-sm-n40 {
    margin: -16rem !important; }
  .mt-sm-n40,
  .my-sm-n40 {
    margin-top: -16rem !important; }
  .mr-sm-n40,
  .mx-sm-n40 {
    margin-right: -16rem !important; }
  .mb-sm-n40,
  .my-sm-n40 {
    margin-bottom: -16rem !important; }
  .ml-sm-n40,
  .mx-sm-n40 {
    margin-left: -16rem !important; }
  .m-sm-n48 {
    margin: -19.2rem !important; }
  .mt-sm-n48,
  .my-sm-n48 {
    margin-top: -19.2rem !important; }
  .mr-sm-n48,
  .mx-sm-n48 {
    margin-right: -19.2rem !important; }
  .mb-sm-n48,
  .my-sm-n48 {
    margin-bottom: -19.2rem !important; }
  .ml-sm-n48,
  .mx-sm-n48 {
    margin-left: -19.2rem !important; }
  .m-sm-n56 {
    margin: -22.4rem !important; }
  .mt-sm-n56,
  .my-sm-n56 {
    margin-top: -22.4rem !important; }
  .mr-sm-n56,
  .mx-sm-n56 {
    margin-right: -22.4rem !important; }
  .mb-sm-n56,
  .my-sm-n56 {
    margin-bottom: -22.4rem !important; }
  .ml-sm-n56,
  .mx-sm-n56 {
    margin-left: -22.4rem !important; }
  .m-sm-n64 {
    margin: -25.6rem !important; }
  .mt-sm-n64,
  .my-sm-n64 {
    margin-top: -25.6rem !important; }
  .mr-sm-n64,
  .mx-sm-n64 {
    margin-right: -25.6rem !important; }
  .mb-sm-n64,
  .my-sm-n64 {
    margin-bottom: -25.6rem !important; }
  .ml-sm-n64,
  .mx-sm-n64 {
    margin-left: -25.6rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.4rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.4rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.4rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.4rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.4rem !important; }
  .m-md-2 {
    margin: 0.8rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.8rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.8rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.8rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.8rem !important; }
  .m-md-3 {
    margin: 1.2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.2rem !important; }
  .m-md-4 {
    margin: 1.6rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.6rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.6rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.6rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.6rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2rem !important; }
  .m-md-px {
    margin: 1px !important; }
  .mt-md-px,
  .my-md-px {
    margin-top: 1px !important; }
  .mr-md-px,
  .mx-md-px {
    margin-right: 1px !important; }
  .mb-md-px,
  .my-md-px {
    margin-bottom: 1px !important; }
  .ml-md-px,
  .mx-md-px {
    margin-left: 1px !important; }
  .m-md-6 {
    margin: 2.4rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2.4rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2.4rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2.4rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2.4rem !important; }
  .m-md-8 {
    margin: 3.2rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.2rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.2rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.2rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.2rem !important; }
  .m-md-10 {
    margin: 4rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 4rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 4rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 4rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 4rem !important; }
  .m-md-12 {
    margin: 4.8rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 4.8rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 4.8rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 4.8rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 4.8rem !important; }
  .m-md-16 {
    margin: 6.4rem !important; }
  .mt-md-16,
  .my-md-16 {
    margin-top: 6.4rem !important; }
  .mr-md-16,
  .mx-md-16 {
    margin-right: 6.4rem !important; }
  .mb-md-16,
  .my-md-16 {
    margin-bottom: 6.4rem !important; }
  .ml-md-16,
  .mx-md-16 {
    margin-left: 6.4rem !important; }
  .m-md-20 {
    margin: 8rem !important; }
  .mt-md-20,
  .my-md-20 {
    margin-top: 8rem !important; }
  .mr-md-20,
  .mx-md-20 {
    margin-right: 8rem !important; }
  .mb-md-20,
  .my-md-20 {
    margin-bottom: 8rem !important; }
  .ml-md-20,
  .mx-md-20 {
    margin-left: 8rem !important; }
  .m-md-24 {
    margin: 9.6rem !important; }
  .mt-md-24,
  .my-md-24 {
    margin-top: 9.6rem !important; }
  .mr-md-24,
  .mx-md-24 {
    margin-right: 9.6rem !important; }
  .mb-md-24,
  .my-md-24 {
    margin-bottom: 9.6rem !important; }
  .ml-md-24,
  .mx-md-24 {
    margin-left: 9.6rem !important; }
  .m-md-32 {
    margin: 12.8rem !important; }
  .mt-md-32,
  .my-md-32 {
    margin-top: 12.8rem !important; }
  .mr-md-32,
  .mx-md-32 {
    margin-right: 12.8rem !important; }
  .mb-md-32,
  .my-md-32 {
    margin-bottom: 12.8rem !important; }
  .ml-md-32,
  .mx-md-32 {
    margin-left: 12.8rem !important; }
  .m-md-40 {
    margin: 16rem !important; }
  .mt-md-40,
  .my-md-40 {
    margin-top: 16rem !important; }
  .mr-md-40,
  .mx-md-40 {
    margin-right: 16rem !important; }
  .mb-md-40,
  .my-md-40 {
    margin-bottom: 16rem !important; }
  .ml-md-40,
  .mx-md-40 {
    margin-left: 16rem !important; }
  .m-md-48 {
    margin: 19.2rem !important; }
  .mt-md-48,
  .my-md-48 {
    margin-top: 19.2rem !important; }
  .mr-md-48,
  .mx-md-48 {
    margin-right: 19.2rem !important; }
  .mb-md-48,
  .my-md-48 {
    margin-bottom: 19.2rem !important; }
  .ml-md-48,
  .mx-md-48 {
    margin-left: 19.2rem !important; }
  .m-md-56 {
    margin: 22.4rem !important; }
  .mt-md-56,
  .my-md-56 {
    margin-top: 22.4rem !important; }
  .mr-md-56,
  .mx-md-56 {
    margin-right: 22.4rem !important; }
  .mb-md-56,
  .my-md-56 {
    margin-bottom: 22.4rem !important; }
  .ml-md-56,
  .mx-md-56 {
    margin-left: 22.4rem !important; }
  .m-md-64 {
    margin: 25.6rem !important; }
  .mt-md-64,
  .my-md-64 {
    margin-top: 25.6rem !important; }
  .mr-md-64,
  .mx-md-64 {
    margin-right: 25.6rem !important; }
  .mb-md-64,
  .my-md-64 {
    margin-bottom: 25.6rem !important; }
  .ml-md-64,
  .mx-md-64 {
    margin-left: 25.6rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.4rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.4rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.4rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.4rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.4rem !important; }
  .p-md-2 {
    padding: 0.8rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.8rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.8rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.8rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.8rem !important; }
  .p-md-3 {
    padding: 1.2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.2rem !important; }
  .p-md-4 {
    padding: 1.6rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.6rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.6rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.6rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.6rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2rem !important; }
  .p-md-px {
    padding: 1px !important; }
  .pt-md-px,
  .py-md-px {
    padding-top: 1px !important; }
  .pr-md-px,
  .px-md-px {
    padding-right: 1px !important; }
  .pb-md-px,
  .py-md-px {
    padding-bottom: 1px !important; }
  .pl-md-px,
  .px-md-px {
    padding-left: 1px !important; }
  .p-md-6 {
    padding: 2.4rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2.4rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2.4rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2.4rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2.4rem !important; }
  .p-md-8 {
    padding: 3.2rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.2rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.2rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.2rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.2rem !important; }
  .p-md-10 {
    padding: 4rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 4rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 4rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 4rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 4rem !important; }
  .p-md-12 {
    padding: 4.8rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 4.8rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 4.8rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 4.8rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 4.8rem !important; }
  .p-md-16 {
    padding: 6.4rem !important; }
  .pt-md-16,
  .py-md-16 {
    padding-top: 6.4rem !important; }
  .pr-md-16,
  .px-md-16 {
    padding-right: 6.4rem !important; }
  .pb-md-16,
  .py-md-16 {
    padding-bottom: 6.4rem !important; }
  .pl-md-16,
  .px-md-16 {
    padding-left: 6.4rem !important; }
  .p-md-20 {
    padding: 8rem !important; }
  .pt-md-20,
  .py-md-20 {
    padding-top: 8rem !important; }
  .pr-md-20,
  .px-md-20 {
    padding-right: 8rem !important; }
  .pb-md-20,
  .py-md-20 {
    padding-bottom: 8rem !important; }
  .pl-md-20,
  .px-md-20 {
    padding-left: 8rem !important; }
  .p-md-24 {
    padding: 9.6rem !important; }
  .pt-md-24,
  .py-md-24 {
    padding-top: 9.6rem !important; }
  .pr-md-24,
  .px-md-24 {
    padding-right: 9.6rem !important; }
  .pb-md-24,
  .py-md-24 {
    padding-bottom: 9.6rem !important; }
  .pl-md-24,
  .px-md-24 {
    padding-left: 9.6rem !important; }
  .p-md-32 {
    padding: 12.8rem !important; }
  .pt-md-32,
  .py-md-32 {
    padding-top: 12.8rem !important; }
  .pr-md-32,
  .px-md-32 {
    padding-right: 12.8rem !important; }
  .pb-md-32,
  .py-md-32 {
    padding-bottom: 12.8rem !important; }
  .pl-md-32,
  .px-md-32 {
    padding-left: 12.8rem !important; }
  .p-md-40 {
    padding: 16rem !important; }
  .pt-md-40,
  .py-md-40 {
    padding-top: 16rem !important; }
  .pr-md-40,
  .px-md-40 {
    padding-right: 16rem !important; }
  .pb-md-40,
  .py-md-40 {
    padding-bottom: 16rem !important; }
  .pl-md-40,
  .px-md-40 {
    padding-left: 16rem !important; }
  .p-md-48 {
    padding: 19.2rem !important; }
  .pt-md-48,
  .py-md-48 {
    padding-top: 19.2rem !important; }
  .pr-md-48,
  .px-md-48 {
    padding-right: 19.2rem !important; }
  .pb-md-48,
  .py-md-48 {
    padding-bottom: 19.2rem !important; }
  .pl-md-48,
  .px-md-48 {
    padding-left: 19.2rem !important; }
  .p-md-56 {
    padding: 22.4rem !important; }
  .pt-md-56,
  .py-md-56 {
    padding-top: 22.4rem !important; }
  .pr-md-56,
  .px-md-56 {
    padding-right: 22.4rem !important; }
  .pb-md-56,
  .py-md-56 {
    padding-bottom: 22.4rem !important; }
  .pl-md-56,
  .px-md-56 {
    padding-left: 22.4rem !important; }
  .p-md-64 {
    padding: 25.6rem !important; }
  .pt-md-64,
  .py-md-64 {
    padding-top: 25.6rem !important; }
  .pr-md-64,
  .px-md-64 {
    padding-right: 25.6rem !important; }
  .pb-md-64,
  .py-md-64 {
    padding-bottom: 25.6rem !important; }
  .pl-md-64,
  .px-md-64 {
    padding-left: 25.6rem !important; }
  .m-md-n1 {
    margin: -0.4rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.4rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.4rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.4rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.4rem !important; }
  .m-md-n2 {
    margin: -0.8rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.8rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.8rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.8rem !important; }
  .m-md-n3 {
    margin: -1.2rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.2rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.2rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.2rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.2rem !important; }
  .m-md-n4 {
    margin: -1.6rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.6rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.6rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.6rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.6rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2rem !important; }
  .m-md-npx {
    margin: -1px !important; }
  .mt-md-npx,
  .my-md-npx {
    margin-top: -1px !important; }
  .mr-md-npx,
  .mx-md-npx {
    margin-right: -1px !important; }
  .mb-md-npx,
  .my-md-npx {
    margin-bottom: -1px !important; }
  .ml-md-npx,
  .mx-md-npx {
    margin-left: -1px !important; }
  .m-md-n6 {
    margin: -2.4rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2.4rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2.4rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2.4rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2.4rem !important; }
  .m-md-n8 {
    margin: -3.2rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.2rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.2rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.2rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.2rem !important; }
  .m-md-n10 {
    margin: -4rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -4rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -4rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -4rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -4rem !important; }
  .m-md-n12 {
    margin: -4.8rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -4.8rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -4.8rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -4.8rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -4.8rem !important; }
  .m-md-n16 {
    margin: -6.4rem !important; }
  .mt-md-n16,
  .my-md-n16 {
    margin-top: -6.4rem !important; }
  .mr-md-n16,
  .mx-md-n16 {
    margin-right: -6.4rem !important; }
  .mb-md-n16,
  .my-md-n16 {
    margin-bottom: -6.4rem !important; }
  .ml-md-n16,
  .mx-md-n16 {
    margin-left: -6.4rem !important; }
  .m-md-n20 {
    margin: -8rem !important; }
  .mt-md-n20,
  .my-md-n20 {
    margin-top: -8rem !important; }
  .mr-md-n20,
  .mx-md-n20 {
    margin-right: -8rem !important; }
  .mb-md-n20,
  .my-md-n20 {
    margin-bottom: -8rem !important; }
  .ml-md-n20,
  .mx-md-n20 {
    margin-left: -8rem !important; }
  .m-md-n24 {
    margin: -9.6rem !important; }
  .mt-md-n24,
  .my-md-n24 {
    margin-top: -9.6rem !important; }
  .mr-md-n24,
  .mx-md-n24 {
    margin-right: -9.6rem !important; }
  .mb-md-n24,
  .my-md-n24 {
    margin-bottom: -9.6rem !important; }
  .ml-md-n24,
  .mx-md-n24 {
    margin-left: -9.6rem !important; }
  .m-md-n32 {
    margin: -12.8rem !important; }
  .mt-md-n32,
  .my-md-n32 {
    margin-top: -12.8rem !important; }
  .mr-md-n32,
  .mx-md-n32 {
    margin-right: -12.8rem !important; }
  .mb-md-n32,
  .my-md-n32 {
    margin-bottom: -12.8rem !important; }
  .ml-md-n32,
  .mx-md-n32 {
    margin-left: -12.8rem !important; }
  .m-md-n40 {
    margin: -16rem !important; }
  .mt-md-n40,
  .my-md-n40 {
    margin-top: -16rem !important; }
  .mr-md-n40,
  .mx-md-n40 {
    margin-right: -16rem !important; }
  .mb-md-n40,
  .my-md-n40 {
    margin-bottom: -16rem !important; }
  .ml-md-n40,
  .mx-md-n40 {
    margin-left: -16rem !important; }
  .m-md-n48 {
    margin: -19.2rem !important; }
  .mt-md-n48,
  .my-md-n48 {
    margin-top: -19.2rem !important; }
  .mr-md-n48,
  .mx-md-n48 {
    margin-right: -19.2rem !important; }
  .mb-md-n48,
  .my-md-n48 {
    margin-bottom: -19.2rem !important; }
  .ml-md-n48,
  .mx-md-n48 {
    margin-left: -19.2rem !important; }
  .m-md-n56 {
    margin: -22.4rem !important; }
  .mt-md-n56,
  .my-md-n56 {
    margin-top: -22.4rem !important; }
  .mr-md-n56,
  .mx-md-n56 {
    margin-right: -22.4rem !important; }
  .mb-md-n56,
  .my-md-n56 {
    margin-bottom: -22.4rem !important; }
  .ml-md-n56,
  .mx-md-n56 {
    margin-left: -22.4rem !important; }
  .m-md-n64 {
    margin: -25.6rem !important; }
  .mt-md-n64,
  .my-md-n64 {
    margin-top: -25.6rem !important; }
  .mr-md-n64,
  .mx-md-n64 {
    margin-right: -25.6rem !important; }
  .mb-md-n64,
  .my-md-n64 {
    margin-bottom: -25.6rem !important; }
  .ml-md-n64,
  .mx-md-n64 {
    margin-left: -25.6rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.4rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.4rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.4rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.4rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.4rem !important; }
  .m-lg-2 {
    margin: 0.8rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.8rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.8rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.8rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.8rem !important; }
  .m-lg-3 {
    margin: 1.2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.2rem !important; }
  .m-lg-4 {
    margin: 1.6rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.6rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.6rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.6rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.6rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2rem !important; }
  .m-lg-px {
    margin: 1px !important; }
  .mt-lg-px,
  .my-lg-px {
    margin-top: 1px !important; }
  .mr-lg-px,
  .mx-lg-px {
    margin-right: 1px !important; }
  .mb-lg-px,
  .my-lg-px {
    margin-bottom: 1px !important; }
  .ml-lg-px,
  .mx-lg-px {
    margin-left: 1px !important; }
  .m-lg-6 {
    margin: 2.4rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2.4rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2.4rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2.4rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2.4rem !important; }
  .m-lg-8 {
    margin: 3.2rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.2rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.2rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.2rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.2rem !important; }
  .m-lg-10 {
    margin: 4rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 4rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 4rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 4rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 4rem !important; }
  .m-lg-12 {
    margin: 4.8rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 4.8rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 4.8rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 4.8rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 4.8rem !important; }
  .m-lg-16 {
    margin: 6.4rem !important; }
  .mt-lg-16,
  .my-lg-16 {
    margin-top: 6.4rem !important; }
  .mr-lg-16,
  .mx-lg-16 {
    margin-right: 6.4rem !important; }
  .mb-lg-16,
  .my-lg-16 {
    margin-bottom: 6.4rem !important; }
  .ml-lg-16,
  .mx-lg-16 {
    margin-left: 6.4rem !important; }
  .m-lg-20 {
    margin: 8rem !important; }
  .mt-lg-20,
  .my-lg-20 {
    margin-top: 8rem !important; }
  .mr-lg-20,
  .mx-lg-20 {
    margin-right: 8rem !important; }
  .mb-lg-20,
  .my-lg-20 {
    margin-bottom: 8rem !important; }
  .ml-lg-20,
  .mx-lg-20 {
    margin-left: 8rem !important; }
  .m-lg-24 {
    margin: 9.6rem !important; }
  .mt-lg-24,
  .my-lg-24 {
    margin-top: 9.6rem !important; }
  .mr-lg-24,
  .mx-lg-24 {
    margin-right: 9.6rem !important; }
  .mb-lg-24,
  .my-lg-24 {
    margin-bottom: 9.6rem !important; }
  .ml-lg-24,
  .mx-lg-24 {
    margin-left: 9.6rem !important; }
  .m-lg-32 {
    margin: 12.8rem !important; }
  .mt-lg-32,
  .my-lg-32 {
    margin-top: 12.8rem !important; }
  .mr-lg-32,
  .mx-lg-32 {
    margin-right: 12.8rem !important; }
  .mb-lg-32,
  .my-lg-32 {
    margin-bottom: 12.8rem !important; }
  .ml-lg-32,
  .mx-lg-32 {
    margin-left: 12.8rem !important; }
  .m-lg-40 {
    margin: 16rem !important; }
  .mt-lg-40,
  .my-lg-40 {
    margin-top: 16rem !important; }
  .mr-lg-40,
  .mx-lg-40 {
    margin-right: 16rem !important; }
  .mb-lg-40,
  .my-lg-40 {
    margin-bottom: 16rem !important; }
  .ml-lg-40,
  .mx-lg-40 {
    margin-left: 16rem !important; }
  .m-lg-48 {
    margin: 19.2rem !important; }
  .mt-lg-48,
  .my-lg-48 {
    margin-top: 19.2rem !important; }
  .mr-lg-48,
  .mx-lg-48 {
    margin-right: 19.2rem !important; }
  .mb-lg-48,
  .my-lg-48 {
    margin-bottom: 19.2rem !important; }
  .ml-lg-48,
  .mx-lg-48 {
    margin-left: 19.2rem !important; }
  .m-lg-56 {
    margin: 22.4rem !important; }
  .mt-lg-56,
  .my-lg-56 {
    margin-top: 22.4rem !important; }
  .mr-lg-56,
  .mx-lg-56 {
    margin-right: 22.4rem !important; }
  .mb-lg-56,
  .my-lg-56 {
    margin-bottom: 22.4rem !important; }
  .ml-lg-56,
  .mx-lg-56 {
    margin-left: 22.4rem !important; }
  .m-lg-64 {
    margin: 25.6rem !important; }
  .mt-lg-64,
  .my-lg-64 {
    margin-top: 25.6rem !important; }
  .mr-lg-64,
  .mx-lg-64 {
    margin-right: 25.6rem !important; }
  .mb-lg-64,
  .my-lg-64 {
    margin-bottom: 25.6rem !important; }
  .ml-lg-64,
  .mx-lg-64 {
    margin-left: 25.6rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.4rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.4rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.4rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.4rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.4rem !important; }
  .p-lg-2 {
    padding: 0.8rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.8rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.8rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.8rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.8rem !important; }
  .p-lg-3 {
    padding: 1.2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.2rem !important; }
  .p-lg-4 {
    padding: 1.6rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.6rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.6rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.6rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.6rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2rem !important; }
  .p-lg-px {
    padding: 1px !important; }
  .pt-lg-px,
  .py-lg-px {
    padding-top: 1px !important; }
  .pr-lg-px,
  .px-lg-px {
    padding-right: 1px !important; }
  .pb-lg-px,
  .py-lg-px {
    padding-bottom: 1px !important; }
  .pl-lg-px,
  .px-lg-px {
    padding-left: 1px !important; }
  .p-lg-6 {
    padding: 2.4rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2.4rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2.4rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2.4rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2.4rem !important; }
  .p-lg-8 {
    padding: 3.2rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.2rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.2rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.2rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.2rem !important; }
  .p-lg-10 {
    padding: 4rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 4rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 4rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 4rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 4rem !important; }
  .p-lg-12 {
    padding: 4.8rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 4.8rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 4.8rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 4.8rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 4.8rem !important; }
  .p-lg-16 {
    padding: 6.4rem !important; }
  .pt-lg-16,
  .py-lg-16 {
    padding-top: 6.4rem !important; }
  .pr-lg-16,
  .px-lg-16 {
    padding-right: 6.4rem !important; }
  .pb-lg-16,
  .py-lg-16 {
    padding-bottom: 6.4rem !important; }
  .pl-lg-16,
  .px-lg-16 {
    padding-left: 6.4rem !important; }
  .p-lg-20 {
    padding: 8rem !important; }
  .pt-lg-20,
  .py-lg-20 {
    padding-top: 8rem !important; }
  .pr-lg-20,
  .px-lg-20 {
    padding-right: 8rem !important; }
  .pb-lg-20,
  .py-lg-20 {
    padding-bottom: 8rem !important; }
  .pl-lg-20,
  .px-lg-20 {
    padding-left: 8rem !important; }
  .p-lg-24 {
    padding: 9.6rem !important; }
  .pt-lg-24,
  .py-lg-24 {
    padding-top: 9.6rem !important; }
  .pr-lg-24,
  .px-lg-24 {
    padding-right: 9.6rem !important; }
  .pb-lg-24,
  .py-lg-24 {
    padding-bottom: 9.6rem !important; }
  .pl-lg-24,
  .px-lg-24 {
    padding-left: 9.6rem !important; }
  .p-lg-32 {
    padding: 12.8rem !important; }
  .pt-lg-32,
  .py-lg-32 {
    padding-top: 12.8rem !important; }
  .pr-lg-32,
  .px-lg-32 {
    padding-right: 12.8rem !important; }
  .pb-lg-32,
  .py-lg-32 {
    padding-bottom: 12.8rem !important; }
  .pl-lg-32,
  .px-lg-32 {
    padding-left: 12.8rem !important; }
  .p-lg-40 {
    padding: 16rem !important; }
  .pt-lg-40,
  .py-lg-40 {
    padding-top: 16rem !important; }
  .pr-lg-40,
  .px-lg-40 {
    padding-right: 16rem !important; }
  .pb-lg-40,
  .py-lg-40 {
    padding-bottom: 16rem !important; }
  .pl-lg-40,
  .px-lg-40 {
    padding-left: 16rem !important; }
  .p-lg-48 {
    padding: 19.2rem !important; }
  .pt-lg-48,
  .py-lg-48 {
    padding-top: 19.2rem !important; }
  .pr-lg-48,
  .px-lg-48 {
    padding-right: 19.2rem !important; }
  .pb-lg-48,
  .py-lg-48 {
    padding-bottom: 19.2rem !important; }
  .pl-lg-48,
  .px-lg-48 {
    padding-left: 19.2rem !important; }
  .p-lg-56 {
    padding: 22.4rem !important; }
  .pt-lg-56,
  .py-lg-56 {
    padding-top: 22.4rem !important; }
  .pr-lg-56,
  .px-lg-56 {
    padding-right: 22.4rem !important; }
  .pb-lg-56,
  .py-lg-56 {
    padding-bottom: 22.4rem !important; }
  .pl-lg-56,
  .px-lg-56 {
    padding-left: 22.4rem !important; }
  .p-lg-64 {
    padding: 25.6rem !important; }
  .pt-lg-64,
  .py-lg-64 {
    padding-top: 25.6rem !important; }
  .pr-lg-64,
  .px-lg-64 {
    padding-right: 25.6rem !important; }
  .pb-lg-64,
  .py-lg-64 {
    padding-bottom: 25.6rem !important; }
  .pl-lg-64,
  .px-lg-64 {
    padding-left: 25.6rem !important; }
  .m-lg-n1 {
    margin: -0.4rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.4rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.4rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.4rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.4rem !important; }
  .m-lg-n2 {
    margin: -0.8rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.8rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.8rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.8rem !important; }
  .m-lg-n3 {
    margin: -1.2rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.2rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.2rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.2rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.2rem !important; }
  .m-lg-n4 {
    margin: -1.6rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.6rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.6rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.6rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.6rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2rem !important; }
  .m-lg-npx {
    margin: -1px !important; }
  .mt-lg-npx,
  .my-lg-npx {
    margin-top: -1px !important; }
  .mr-lg-npx,
  .mx-lg-npx {
    margin-right: -1px !important; }
  .mb-lg-npx,
  .my-lg-npx {
    margin-bottom: -1px !important; }
  .ml-lg-npx,
  .mx-lg-npx {
    margin-left: -1px !important; }
  .m-lg-n6 {
    margin: -2.4rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2.4rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2.4rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2.4rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2.4rem !important; }
  .m-lg-n8 {
    margin: -3.2rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.2rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.2rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.2rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.2rem !important; }
  .m-lg-n10 {
    margin: -4rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -4rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -4rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -4rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -4rem !important; }
  .m-lg-n12 {
    margin: -4.8rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -4.8rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -4.8rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -4.8rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -4.8rem !important; }
  .m-lg-n16 {
    margin: -6.4rem !important; }
  .mt-lg-n16,
  .my-lg-n16 {
    margin-top: -6.4rem !important; }
  .mr-lg-n16,
  .mx-lg-n16 {
    margin-right: -6.4rem !important; }
  .mb-lg-n16,
  .my-lg-n16 {
    margin-bottom: -6.4rem !important; }
  .ml-lg-n16,
  .mx-lg-n16 {
    margin-left: -6.4rem !important; }
  .m-lg-n20 {
    margin: -8rem !important; }
  .mt-lg-n20,
  .my-lg-n20 {
    margin-top: -8rem !important; }
  .mr-lg-n20,
  .mx-lg-n20 {
    margin-right: -8rem !important; }
  .mb-lg-n20,
  .my-lg-n20 {
    margin-bottom: -8rem !important; }
  .ml-lg-n20,
  .mx-lg-n20 {
    margin-left: -8rem !important; }
  .m-lg-n24 {
    margin: -9.6rem !important; }
  .mt-lg-n24,
  .my-lg-n24 {
    margin-top: -9.6rem !important; }
  .mr-lg-n24,
  .mx-lg-n24 {
    margin-right: -9.6rem !important; }
  .mb-lg-n24,
  .my-lg-n24 {
    margin-bottom: -9.6rem !important; }
  .ml-lg-n24,
  .mx-lg-n24 {
    margin-left: -9.6rem !important; }
  .m-lg-n32 {
    margin: -12.8rem !important; }
  .mt-lg-n32,
  .my-lg-n32 {
    margin-top: -12.8rem !important; }
  .mr-lg-n32,
  .mx-lg-n32 {
    margin-right: -12.8rem !important; }
  .mb-lg-n32,
  .my-lg-n32 {
    margin-bottom: -12.8rem !important; }
  .ml-lg-n32,
  .mx-lg-n32 {
    margin-left: -12.8rem !important; }
  .m-lg-n40 {
    margin: -16rem !important; }
  .mt-lg-n40,
  .my-lg-n40 {
    margin-top: -16rem !important; }
  .mr-lg-n40,
  .mx-lg-n40 {
    margin-right: -16rem !important; }
  .mb-lg-n40,
  .my-lg-n40 {
    margin-bottom: -16rem !important; }
  .ml-lg-n40,
  .mx-lg-n40 {
    margin-left: -16rem !important; }
  .m-lg-n48 {
    margin: -19.2rem !important; }
  .mt-lg-n48,
  .my-lg-n48 {
    margin-top: -19.2rem !important; }
  .mr-lg-n48,
  .mx-lg-n48 {
    margin-right: -19.2rem !important; }
  .mb-lg-n48,
  .my-lg-n48 {
    margin-bottom: -19.2rem !important; }
  .ml-lg-n48,
  .mx-lg-n48 {
    margin-left: -19.2rem !important; }
  .m-lg-n56 {
    margin: -22.4rem !important; }
  .mt-lg-n56,
  .my-lg-n56 {
    margin-top: -22.4rem !important; }
  .mr-lg-n56,
  .mx-lg-n56 {
    margin-right: -22.4rem !important; }
  .mb-lg-n56,
  .my-lg-n56 {
    margin-bottom: -22.4rem !important; }
  .ml-lg-n56,
  .mx-lg-n56 {
    margin-left: -22.4rem !important; }
  .m-lg-n64 {
    margin: -25.6rem !important; }
  .mt-lg-n64,
  .my-lg-n64 {
    margin-top: -25.6rem !important; }
  .mr-lg-n64,
  .mx-lg-n64 {
    margin-right: -25.6rem !important; }
  .mb-lg-n64,
  .my-lg-n64 {
    margin-bottom: -25.6rem !important; }
  .ml-lg-n64,
  .mx-lg-n64 {
    margin-left: -25.6rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.4rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.4rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.4rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.4rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.4rem !important; }
  .m-xl-2 {
    margin: 0.8rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.8rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.8rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.8rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.8rem !important; }
  .m-xl-3 {
    margin: 1.2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.2rem !important; }
  .m-xl-4 {
    margin: 1.6rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.6rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.6rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.6rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.6rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2rem !important; }
  .m-xl-px {
    margin: 1px !important; }
  .mt-xl-px,
  .my-xl-px {
    margin-top: 1px !important; }
  .mr-xl-px,
  .mx-xl-px {
    margin-right: 1px !important; }
  .mb-xl-px,
  .my-xl-px {
    margin-bottom: 1px !important; }
  .ml-xl-px,
  .mx-xl-px {
    margin-left: 1px !important; }
  .m-xl-6 {
    margin: 2.4rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2.4rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2.4rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2.4rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2.4rem !important; }
  .m-xl-8 {
    margin: 3.2rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.2rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.2rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.2rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.2rem !important; }
  .m-xl-10 {
    margin: 4rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 4rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 4rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 4rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 4rem !important; }
  .m-xl-12 {
    margin: 4.8rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 4.8rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 4.8rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 4.8rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 4.8rem !important; }
  .m-xl-16 {
    margin: 6.4rem !important; }
  .mt-xl-16,
  .my-xl-16 {
    margin-top: 6.4rem !important; }
  .mr-xl-16,
  .mx-xl-16 {
    margin-right: 6.4rem !important; }
  .mb-xl-16,
  .my-xl-16 {
    margin-bottom: 6.4rem !important; }
  .ml-xl-16,
  .mx-xl-16 {
    margin-left: 6.4rem !important; }
  .m-xl-20 {
    margin: 8rem !important; }
  .mt-xl-20,
  .my-xl-20 {
    margin-top: 8rem !important; }
  .mr-xl-20,
  .mx-xl-20 {
    margin-right: 8rem !important; }
  .mb-xl-20,
  .my-xl-20 {
    margin-bottom: 8rem !important; }
  .ml-xl-20,
  .mx-xl-20 {
    margin-left: 8rem !important; }
  .m-xl-24 {
    margin: 9.6rem !important; }
  .mt-xl-24,
  .my-xl-24 {
    margin-top: 9.6rem !important; }
  .mr-xl-24,
  .mx-xl-24 {
    margin-right: 9.6rem !important; }
  .mb-xl-24,
  .my-xl-24 {
    margin-bottom: 9.6rem !important; }
  .ml-xl-24,
  .mx-xl-24 {
    margin-left: 9.6rem !important; }
  .m-xl-32 {
    margin: 12.8rem !important; }
  .mt-xl-32,
  .my-xl-32 {
    margin-top: 12.8rem !important; }
  .mr-xl-32,
  .mx-xl-32 {
    margin-right: 12.8rem !important; }
  .mb-xl-32,
  .my-xl-32 {
    margin-bottom: 12.8rem !important; }
  .ml-xl-32,
  .mx-xl-32 {
    margin-left: 12.8rem !important; }
  .m-xl-40 {
    margin: 16rem !important; }
  .mt-xl-40,
  .my-xl-40 {
    margin-top: 16rem !important; }
  .mr-xl-40,
  .mx-xl-40 {
    margin-right: 16rem !important; }
  .mb-xl-40,
  .my-xl-40 {
    margin-bottom: 16rem !important; }
  .ml-xl-40,
  .mx-xl-40 {
    margin-left: 16rem !important; }
  .m-xl-48 {
    margin: 19.2rem !important; }
  .mt-xl-48,
  .my-xl-48 {
    margin-top: 19.2rem !important; }
  .mr-xl-48,
  .mx-xl-48 {
    margin-right: 19.2rem !important; }
  .mb-xl-48,
  .my-xl-48 {
    margin-bottom: 19.2rem !important; }
  .ml-xl-48,
  .mx-xl-48 {
    margin-left: 19.2rem !important; }
  .m-xl-56 {
    margin: 22.4rem !important; }
  .mt-xl-56,
  .my-xl-56 {
    margin-top: 22.4rem !important; }
  .mr-xl-56,
  .mx-xl-56 {
    margin-right: 22.4rem !important; }
  .mb-xl-56,
  .my-xl-56 {
    margin-bottom: 22.4rem !important; }
  .ml-xl-56,
  .mx-xl-56 {
    margin-left: 22.4rem !important; }
  .m-xl-64 {
    margin: 25.6rem !important; }
  .mt-xl-64,
  .my-xl-64 {
    margin-top: 25.6rem !important; }
  .mr-xl-64,
  .mx-xl-64 {
    margin-right: 25.6rem !important; }
  .mb-xl-64,
  .my-xl-64 {
    margin-bottom: 25.6rem !important; }
  .ml-xl-64,
  .mx-xl-64 {
    margin-left: 25.6rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.4rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.4rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.4rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.4rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.4rem !important; }
  .p-xl-2 {
    padding: 0.8rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.8rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.8rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.8rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.8rem !important; }
  .p-xl-3 {
    padding: 1.2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.2rem !important; }
  .p-xl-4 {
    padding: 1.6rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.6rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.6rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.6rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.6rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2rem !important; }
  .p-xl-px {
    padding: 1px !important; }
  .pt-xl-px,
  .py-xl-px {
    padding-top: 1px !important; }
  .pr-xl-px,
  .px-xl-px {
    padding-right: 1px !important; }
  .pb-xl-px,
  .py-xl-px {
    padding-bottom: 1px !important; }
  .pl-xl-px,
  .px-xl-px {
    padding-left: 1px !important; }
  .p-xl-6 {
    padding: 2.4rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2.4rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2.4rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2.4rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2.4rem !important; }
  .p-xl-8 {
    padding: 3.2rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.2rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.2rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.2rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.2rem !important; }
  .p-xl-10 {
    padding: 4rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 4rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 4rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 4rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 4rem !important; }
  .p-xl-12 {
    padding: 4.8rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 4.8rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 4.8rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 4.8rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 4.8rem !important; }
  .p-xl-16 {
    padding: 6.4rem !important; }
  .pt-xl-16,
  .py-xl-16 {
    padding-top: 6.4rem !important; }
  .pr-xl-16,
  .px-xl-16 {
    padding-right: 6.4rem !important; }
  .pb-xl-16,
  .py-xl-16 {
    padding-bottom: 6.4rem !important; }
  .pl-xl-16,
  .px-xl-16 {
    padding-left: 6.4rem !important; }
  .p-xl-20 {
    padding: 8rem !important; }
  .pt-xl-20,
  .py-xl-20 {
    padding-top: 8rem !important; }
  .pr-xl-20,
  .px-xl-20 {
    padding-right: 8rem !important; }
  .pb-xl-20,
  .py-xl-20 {
    padding-bottom: 8rem !important; }
  .pl-xl-20,
  .px-xl-20 {
    padding-left: 8rem !important; }
  .p-xl-24 {
    padding: 9.6rem !important; }
  .pt-xl-24,
  .py-xl-24 {
    padding-top: 9.6rem !important; }
  .pr-xl-24,
  .px-xl-24 {
    padding-right: 9.6rem !important; }
  .pb-xl-24,
  .py-xl-24 {
    padding-bottom: 9.6rem !important; }
  .pl-xl-24,
  .px-xl-24 {
    padding-left: 9.6rem !important; }
  .p-xl-32 {
    padding: 12.8rem !important; }
  .pt-xl-32,
  .py-xl-32 {
    padding-top: 12.8rem !important; }
  .pr-xl-32,
  .px-xl-32 {
    padding-right: 12.8rem !important; }
  .pb-xl-32,
  .py-xl-32 {
    padding-bottom: 12.8rem !important; }
  .pl-xl-32,
  .px-xl-32 {
    padding-left: 12.8rem !important; }
  .p-xl-40 {
    padding: 16rem !important; }
  .pt-xl-40,
  .py-xl-40 {
    padding-top: 16rem !important; }
  .pr-xl-40,
  .px-xl-40 {
    padding-right: 16rem !important; }
  .pb-xl-40,
  .py-xl-40 {
    padding-bottom: 16rem !important; }
  .pl-xl-40,
  .px-xl-40 {
    padding-left: 16rem !important; }
  .p-xl-48 {
    padding: 19.2rem !important; }
  .pt-xl-48,
  .py-xl-48 {
    padding-top: 19.2rem !important; }
  .pr-xl-48,
  .px-xl-48 {
    padding-right: 19.2rem !important; }
  .pb-xl-48,
  .py-xl-48 {
    padding-bottom: 19.2rem !important; }
  .pl-xl-48,
  .px-xl-48 {
    padding-left: 19.2rem !important; }
  .p-xl-56 {
    padding: 22.4rem !important; }
  .pt-xl-56,
  .py-xl-56 {
    padding-top: 22.4rem !important; }
  .pr-xl-56,
  .px-xl-56 {
    padding-right: 22.4rem !important; }
  .pb-xl-56,
  .py-xl-56 {
    padding-bottom: 22.4rem !important; }
  .pl-xl-56,
  .px-xl-56 {
    padding-left: 22.4rem !important; }
  .p-xl-64 {
    padding: 25.6rem !important; }
  .pt-xl-64,
  .py-xl-64 {
    padding-top: 25.6rem !important; }
  .pr-xl-64,
  .px-xl-64 {
    padding-right: 25.6rem !important; }
  .pb-xl-64,
  .py-xl-64 {
    padding-bottom: 25.6rem !important; }
  .pl-xl-64,
  .px-xl-64 {
    padding-left: 25.6rem !important; }
  .m-xl-n1 {
    margin: -0.4rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.4rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.4rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.4rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.4rem !important; }
  .m-xl-n2 {
    margin: -0.8rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.8rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.8rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.8rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.8rem !important; }
  .m-xl-n3 {
    margin: -1.2rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.2rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.2rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.2rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.2rem !important; }
  .m-xl-n4 {
    margin: -1.6rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.6rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.6rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.6rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.6rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2rem !important; }
  .m-xl-npx {
    margin: -1px !important; }
  .mt-xl-npx,
  .my-xl-npx {
    margin-top: -1px !important; }
  .mr-xl-npx,
  .mx-xl-npx {
    margin-right: -1px !important; }
  .mb-xl-npx,
  .my-xl-npx {
    margin-bottom: -1px !important; }
  .ml-xl-npx,
  .mx-xl-npx {
    margin-left: -1px !important; }
  .m-xl-n6 {
    margin: -2.4rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2.4rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2.4rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2.4rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2.4rem !important; }
  .m-xl-n8 {
    margin: -3.2rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.2rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.2rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.2rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.2rem !important; }
  .m-xl-n10 {
    margin: -4rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -4rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -4rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -4rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -4rem !important; }
  .m-xl-n12 {
    margin: -4.8rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -4.8rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -4.8rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -4.8rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -4.8rem !important; }
  .m-xl-n16 {
    margin: -6.4rem !important; }
  .mt-xl-n16,
  .my-xl-n16 {
    margin-top: -6.4rem !important; }
  .mr-xl-n16,
  .mx-xl-n16 {
    margin-right: -6.4rem !important; }
  .mb-xl-n16,
  .my-xl-n16 {
    margin-bottom: -6.4rem !important; }
  .ml-xl-n16,
  .mx-xl-n16 {
    margin-left: -6.4rem !important; }
  .m-xl-n20 {
    margin: -8rem !important; }
  .mt-xl-n20,
  .my-xl-n20 {
    margin-top: -8rem !important; }
  .mr-xl-n20,
  .mx-xl-n20 {
    margin-right: -8rem !important; }
  .mb-xl-n20,
  .my-xl-n20 {
    margin-bottom: -8rem !important; }
  .ml-xl-n20,
  .mx-xl-n20 {
    margin-left: -8rem !important; }
  .m-xl-n24 {
    margin: -9.6rem !important; }
  .mt-xl-n24,
  .my-xl-n24 {
    margin-top: -9.6rem !important; }
  .mr-xl-n24,
  .mx-xl-n24 {
    margin-right: -9.6rem !important; }
  .mb-xl-n24,
  .my-xl-n24 {
    margin-bottom: -9.6rem !important; }
  .ml-xl-n24,
  .mx-xl-n24 {
    margin-left: -9.6rem !important; }
  .m-xl-n32 {
    margin: -12.8rem !important; }
  .mt-xl-n32,
  .my-xl-n32 {
    margin-top: -12.8rem !important; }
  .mr-xl-n32,
  .mx-xl-n32 {
    margin-right: -12.8rem !important; }
  .mb-xl-n32,
  .my-xl-n32 {
    margin-bottom: -12.8rem !important; }
  .ml-xl-n32,
  .mx-xl-n32 {
    margin-left: -12.8rem !important; }
  .m-xl-n40 {
    margin: -16rem !important; }
  .mt-xl-n40,
  .my-xl-n40 {
    margin-top: -16rem !important; }
  .mr-xl-n40,
  .mx-xl-n40 {
    margin-right: -16rem !important; }
  .mb-xl-n40,
  .my-xl-n40 {
    margin-bottom: -16rem !important; }
  .ml-xl-n40,
  .mx-xl-n40 {
    margin-left: -16rem !important; }
  .m-xl-n48 {
    margin: -19.2rem !important; }
  .mt-xl-n48,
  .my-xl-n48 {
    margin-top: -19.2rem !important; }
  .mr-xl-n48,
  .mx-xl-n48 {
    margin-right: -19.2rem !important; }
  .mb-xl-n48,
  .my-xl-n48 {
    margin-bottom: -19.2rem !important; }
  .ml-xl-n48,
  .mx-xl-n48 {
    margin-left: -19.2rem !important; }
  .m-xl-n56 {
    margin: -22.4rem !important; }
  .mt-xl-n56,
  .my-xl-n56 {
    margin-top: -22.4rem !important; }
  .mr-xl-n56,
  .mx-xl-n56 {
    margin-right: -22.4rem !important; }
  .mb-xl-n56,
  .my-xl-n56 {
    margin-bottom: -22.4rem !important; }
  .ml-xl-n56,
  .mx-xl-n56 {
    margin-left: -22.4rem !important; }
  .m-xl-n64 {
    margin: -25.6rem !important; }
  .mt-xl-n64,
  .my-xl-n64 {
    margin-top: -25.6rem !important; }
  .mr-xl-n64,
  .mx-xl-n64 {
    margin-right: -25.6rem !important; }
  .mb-xl-n64,
  .my-xl-n64 {
    margin-bottom: -25.6rem !important; }
  .ml-xl-n64,
  .mx-xl-n64 {
    margin-left: -25.6rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.rw-btn,
.rw-input-reset,
.rw-input,
.rw-dropdown-inner-input,
.rw-dropdown-list-search {
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  background-image: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  touch-action: manipulation; }

.rw-btn::-moz-focus-inner {
  padding: 0;
  border: 0; }

select.rw-input {
  text-transform: none; }

html input[type="button"].rw-input {
  -webkit-appearance: button;
  cursor: pointer; }

textarea.rw-input {
  overflow: auto;
  resize: vertical; }

button[disabled].rw-input,
fieldset[disabled] .rw-input,
html input[disabled].rw-input {
  cursor: not-allowed; }

button.rw-input::-moz-focus-inner,
input.rw-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/* -------------- */
.rw-btn {
  position: relative;
  color: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none; }
  .rw-btn:focus {
    z-index: 1;
    box-shadow: 0 0 0 1px #2779ff; }
    [data-intent='mouse'] .rw-btn:focus {
      z-index: auto;
      box-shadow: none; }
  .rw-state-readonly .rw-btn,
  .rw-state-disabled .rw-btn {
    cursor: not-allowed; }

.rw-btn-select {
  opacity: 0.75;
  transition: opacity 150ms ease-in; }
  .rw-btn-select:hover,
  .rw-state-focus .rw-btn-select,
  :hover > .rw-btn-select {
    opacity: 1; }

.rw-btn-primary {
  white-space: normal;
  line-height: 2em;
  border-radius: 5px; }
  .rw-btn-primary:hover {
    background-color: #e6e6e6; }

.rw-btn-select[disabled],
.rw-btn-primary[disabled],
fieldset[disabled] .rw-btn-select,
fieldset[disabled] .rw-btn-primary {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
  pointer-events: none; }

/* for debugging */
@keyframes react-widgets-autofill-start {
  from {
    /**/ }
  to {
    /**/ } }

@keyframes react-widgets-autofill-cancel {
  from {
    /**/ }
  to {
    /**/ } }

.rw-sr {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.rw-outline-none {
  outline: none; }

.rw-widget {
  background-clip: border-box;
  border: none;
  color: #bfbfbf;
  font-size: 1.4rem;
  font-family: inherit;
  outline: none;
  position: relative; }
  .rw-widget,
  .rw-widget * {
    box-sizing: border-box; }
  .rw-widget:before,
  .rw-widget *:before, .rw-widget:after,
  .rw-widget *:after {
    box-sizing: border-box; }
  .rw-widget > .rw-widget-container {
    width: 100%;
    margin: 0; }

.rw-widget-container {
  background-color: transparent;
  border: transparent 1px solid;
  border-radius: 5px; }
  .rw-widget-container.rw-state-focus, .rw-widget-container.rw-state-focus:hover,
  .rw-state-focus > .rw-widget-container,
  .rw-state-focus > .rw-widget-container:hover {
    background-color: #ffffff;
    border-color: transparent;
    box-shadow: 0px 0px 0 4px rgba(39, 121, 255, 0.4), 0px 0px 0 1px #2779ff; }
  .rw-widget-container.rw-state-readonly,
  .rw-state-readonly > .rw-widget-container {
    cursor: not-allowed; }
  .rw-widget-container.rw-state-disabled,
  .rw-state-disabled > .rw-widget-container,
  fieldset[disabled] .rw-widget-container {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .rw-widget-container.rw-state-disabled, .rw-widget-container.rw-state-disabled:hover, .rw-widget-container.rw-state-disabled:active,
    .rw-state-disabled > .rw-widget-container,
    .rw-state-disabled > .rw-widget-container:hover,
    .rw-state-disabled > .rw-widget-container:active,
    fieldset[disabled] .rw-widget-container,
    fieldset[disabled] .rw-widget-container:hover,
    fieldset[disabled] .rw-widget-container:active {
      box-shadow: none;
      box-shadow: none;
      cursor: not-allowed; }

.rw-widget-picker {
  overflow: hidden;
  display: flex;
  min-height: 4rem; }
  .rw-widget-picker > *:first-child {
    border: none;
    outline: none;
    width: 100%;
    flex: 1 1 0; }
  fieldset[disabled] .rw-widget-picker,
  .rw-state-disabled > .rw-widget-picker {
    background-color: #000000; }

.rw-select {
  cursor: pointer; }
  .rw-select > * {
    width: 1.9em;
    height: 100%; }
  .rw-state-readonly .rw-select,
  .rw-state-disabled .rw-select {
    cursor: not-allowed; }

.rw-select-bordered {
  cursor: pointer;
  border: none;
  border-left: transparent 1px solid; }
  .rw-select-bordered:hover, .rw-select-bordered:active {
    background-color: #e6e6e6; }
  .rw-select-bordered:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .rw-state-disabled .rw-select-bordered, .rw-state-disabled .rw-select-bordered:hover, .rw-state-disabled .rw-select-bordered:active,
  .rw-state-readonly .rw-select-bordered,
  .rw-state-readonly .rw-select-bordered:hover,
  .rw-state-readonly .rw-select-bordered:active,
  fieldset[disabled] .rw-select-bordered,
  fieldset[disabled] .rw-select-bordered:hover,
  fieldset[disabled] .rw-select-bordered:active {
    cursor: not-allowed;
    background-color: inherit;
    background-image: none;
    box-shadow: none; }
  .rw-rtl .rw-select-bordered {
    border-right: transparent 1px solid;
    border-left: none; }

.rw-rtl {
  direction: rtl; }


.rw-input-reset,
.rw-input,
.rw-dropdown-inner-input,
.rw-dropdown-list-search {
  outline: 0; }
  .rw-input-reset::-moz-placeholder, .rw-input::-moz-placeholder, .rw-dropdown-inner-input::-moz-placeholder,
  .rw-dropdown-list-search::-moz-placeholder {
    color: #999;
    opacity: 1; }
  .rw-input-reset:-ms-input-placeholder, .rw-input:-ms-input-placeholder, .rw-dropdown-inner-input:-ms-input-placeholder,
  .rw-dropdown-list-search:-ms-input-placeholder {
    color: #999; }
  .rw-input-reset::-webkit-input-placeholder, .rw-input::-webkit-input-placeholder, .rw-dropdown-inner-input::-webkit-input-placeholder,
  .rw-dropdown-list-search::-webkit-input-placeholder {
    color: #999; }

.rw-input {
  color: inherit;
  padding: 0 1.5rem;
  background-color: transparent; }
  .rw-input[type='text']::-ms-clear {
    display: none; }
  .rw-input[disabled],
  fieldset[disabled] .rw-input {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
    background-color: #000000;
    border-color: #000000; }
  .rw-input[readonly] {
    cursor: not-allowed; }

.rw-placeholder {
  color: #999; }

.rw-detect-autofill:-webkit-autofill {
  animation-name: react-widgets-autofill-start;
  -webkit-transition: background-color 50000s ease-in-out 0s;
  transition: background-color 50000s ease-in-out 0s; }

.rw-detect-autofill:not(:-webkit-autofill) {
  animation-name: react-widgets-autofill-cancel; }

.rw-webkit-autofill .rw-widget-container,
.rw-input:-webkit-autofill {
  background-color: #e8f0fe !important;
  background-image: none !important;
  color: black !important; }

.rw-widget-input {
  box-shadow: none; }

.rw-widget-input.rw-state-focus {
  box-shadow: 0px 0px 0 4px rgba(39, 121, 255, 0.4), 0px 0px 0 1px #2779ff, none; }

.rw-list {
  font-size: 1em;
  overflow-x: visible;
  overflow-y: auto;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.rw-list-option {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #1a1a1a;
  cursor: pointer;
  border: 1px solid transparent; }
  .rw-list-option:hover, .rw-list-option:hover:hover, .rw-list-option.rw-state-focus, .rw-list-option.rw-state-focus:hover {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    color: #1a1a1a; }
  .rw-list-option.rw-state-selected, .rw-list-option.rw-state-selected:hover {
    background-color: #2779ff;
    border-color: #007bff;
    color: #ffffff; }
  fieldset[disabled] .rw-list-option, .rw-list-option.rw-state-disabled, .rw-list-option.rw-state-readonly {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    box-shadow: none;
    cursor: not-allowed;
    color: gray;
    opacity: 0.7; }

.rw-list-empty,
.rw-list-option,
.rw-list-optgroup {
  padding: 0 2rem;
  outline: 0; }

.rw-list-optgroup {
  font-weight: bold;
  padding-top: 7px; }

.rw-list-option-create {
  border-top: 1px #ccc solid; }

.rw-dropdown-inner-input,
.rw-dropdown-list-search {
  padding: 0; }

.rw-dropdown-list {
  width: 100%; }

.rw-dropdown-list-search {
  position: absolute;
  top: 0;
  bottom: 0; }

.rw-dropdown-list-input {
  background-color: transparent;
  vertical-align: middle;
  align-self: center;
  padding-right: 0;
  /* ellipsis */ }
  .rw-dropdown-list-input {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .rw-rtl .rw-dropdown-list-input {
    padding-right: 1.5rem;
    padding-left: 0; }

.rw-multiselect {
  cursor: text; }
  .rw-multiselect > .rw-widget-picker {
    height: auto; }
  .rw-multiselect .rw-input-reset, .rw-multiselect .rw-input, .rw-multiselect .rw-dropdown-inner-input,
  .rw-multiselect .rw-dropdown-list-search {
    height: calc(4rem - 2px);
    border-width: 0;
    padding: 0 1.5rem;
    max-width: 100%; }
  .rw-multiselect .rw-select {
    vertical-align: middle; }
    .rw-multiselect .rw-select, .rw-multiselect .rw-select:hover, .rw-multiselect .rw-select:active {
      box-shadow: none;
      background: none; }

.rw-multiselect-taglist {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1 1 0%;
  outline: none; }

.rw-multiselect-tag {
  display: inline-flex;
  color: #ffffff;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
  min-height: 3rem;
  border-radius: 5px;
  background-color: #0f326c;
  border: 1px solid #0f326c;
  cursor: default;
  max-width: 100%;
  align-items: center; }
  .rw-rtl .rw-multiselect-tag {
    margin-left: 0;
    margin-right: 0.4rem;
    padding: 0 1rem 0 1rem; }
  .rw-multiselect-tag.rw-state-focus {
    z-index: 1;
    box-shadow: 0 0 0 1px #2779ff; }
  .rw-multiselect-tag.rw-state-readonly, .rw-multiselect-tag.rw-state-disabled,
  .rw-state-readonly .rw-multiselect-tag,
  .rw-state-disabled .rw-multiselect-tag,
  fieldset[disabled] .rw-multiselect-tag {
    cursor: not-allowed; }
  .rw-multiselect-tag.rw-state-disabled,
  .rw-state-disabled .rw-multiselect-tag,
  fieldset[disabled] .rw-multiselect-tag {
    opacity: 0.65; }
  fieldset[disabled] .rw-multiselect-tag {
    box-shadow: none;
    cursor: not-allowed; }

.rw-multiselect-tag-label {
  padding: 0 1rem 0 1rem; }

.rw-multiselect-tag-btn {
  color: #ffffff;
  align-self: stretch;
  padding: 0 1rem; }
  .rw-rtl .rw-multiselect-tag-btn {
    margin-left: 0;
    margin-right: 0.25em; }

.rw-popup-container {
  position: absolute;
  z-index: 1005;
  top: 100%;
  left: 0;
  right: 0;
  padding: 0 0; }
  .rw-popup-container.rw-dropup {
    top: auto;
    bottom: 100%; }
  .rw-state-focus .rw-popup-container {
    z-index: 1006; }

.rw-slide-transition {
  margin-bottom: 0; }
  .rw-dropup > .rw-slide-transition {
    margin-bottom: 0;
    margin-top: 0; }

.rw-popup {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 2px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border: #ccc 1px solid;
  background: #fff; }
  .rw-popup .rw-list {
    max-height: 250px; }
  .rw-dropup .rw-popup {
    margin-top: 0;
    margin-bottom: 2px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15); }

.rw-slide-transition {
  transition: transform 130ms, opacity 100ms; }

.rw-slide-transition-entering {
  overflow: hidden; }
  .rw-slide-transition-entering .rw-slide-transition {
    transform: translateY(0);
    opacity: 1;
    transition-timing-function: ease-out; }

.rw-slide-transition-exiting .rw-slide-transition {
  transition-timing-function: ease-in; }

.rw-slide-transition-exiting,
.rw-slide-transition-exited {
  overflow: hidden; }
  .rw-slide-transition-exiting .rw-slide-transition,
  .rw-slide-transition-exited .rw-slide-transition {
    opacity: 0;
    transform: translateY(-10%); }
  .rw-slide-transition-exiting.rw-dropup .rw-slide-transition,
  .rw-slide-transition-exited.rw-dropup .rw-slide-transition {
    opacity: 0;
    transform: translateY(10%); }

.rw-slide-transition-exited {
  display: none; }

.rw-widget {
  font-weight: 300; }


.rw-input-reset,
.rw-input,
.rw-dropdown-inner-input,
.rw-dropdown-list-search {
  caret-color: #2779ff; }

.rw-btn-select {
  opacity: 1; }

.rw-popup {
  border: none;
  margin-bottom: 10px;
  overflow: hidden; }

.rw-list-option {
  line-height: 35px; }

:root {
  --current-bg-color: #1a1a1a; }

html {
  font-size: 10px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body {
  --fc-layout-pad-0: 0;
  --fc-layout-pad-1: 0.4rem;
  --fc-layout-pad-2: 0.8rem;
  --fc-layout-pad-3: 1.2rem;
  --fc-layout-pad-4: 1.6rem;
  --fc-layout-pad-5: 2rem;
  --fc-layout-pad-px: 1px;
  --fc-layout-pad-6: 2.4rem;
  --fc-layout-pad-8: 3.2rem;
  --fc-layout-pad-10: 4rem;
  --fc-layout-pad-12: 4.8rem;
  --fc-layout-pad-16: 6.4rem;
  --fc-layout-pad-20: 8rem;
  --fc-layout-pad-24: 9.6rem;
  --fc-layout-pad-32: 12.8rem;
  --fc-layout-pad-40: 16rem;
  --fc-layout-pad-48: 19.2rem;
  --fc-layout-pad-56: 22.4rem;
  --fc-layout-pad-64: 25.6rem;
  padding: 0; }

svg {
  vertical-align: initial; }

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit; }

button {
  padding: 0;
  margin: 0;
  border: none;
  box-shadow: none;
  background: none;
  font: inherit;
  color: inherit; }

a:active, a.active {
  color: #2779ff; }

a:focus, a:focus {
  outline: 3px solid rgba(39, 121, 255, 0.4);
  outline: 3px auto rgba(39, 121, 255, 0.4); }

strong {
  font-weight: 700; }

.nav-tabs {
  margin-bottom: 4rem; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    padding: 15px;
    border: 0;
    border-bottom: 2px solid transparent; }
    .nav-tabs .nav-link.active {
      border-bottom-color: #2779ff;
      color: #ffffff; }

.dropdown-menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15); }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.flex-none {
  flex: none !important; }

/*
STOP! New styles likely do not belong here. prefer localized styles,
generally relevant ones belong in library.
*/
.modal-content {
  overflow: visible !important; }

.modal-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }
