.FileViewerPage-styles-module--annotation--3TjTV {
  height: auto;
  flex: 1;
  text-align: left !important;
  padding: 0 !important; }
  .FileViewerPage-styles-module--annotation--3TjTV pre {
    color: #fff;
    overflow: auto;
    max-height: 20rem;
    padding: 10px 15px;
    margin: 0; }

tr.FileViewerPage-styles-module--disabled--1P06g td {
  text-decoration: line-through; }

.FileViewerPage-styles-module--button--37WTv {
  padding: 10px;
  align-items: center; }
