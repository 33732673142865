.AssignmentForm-Shortcuts-module--cls1--1JbNI {
  /*!*/ }

.AssignmentForm-Shortcuts-module--cls2--34jQm {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  bottom: 0;
  padding: 1.6rem;
  width: 30rem; }
