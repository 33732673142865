.Lightbox-Description-module--cls1--1zk3R {
  /*!*/ }

.Lightbox-Description-module--cls2--3JW5P {
  flex: 1 0 0;
  background-color: black;
  margin: 1.5rem;
  padding: 1.5rem;
  align-self: center;
  color: white;
  max-height: 10rem;
  overflow: auto; }
