.InferenceResultListPage-styles-module--selectRow--2LEws {
  height: 14rem;
  border-radius: 0.8rem;
  background-color: #333333;
  padding-left: 2rem;
  padding-right: 2rem; }

.InferenceResultListPage-styles-module--projectDropdown--3am5f {
  width: 45%; }

.InferenceResultListPage-styles-module--versionDropdown--li5zz {
  width: 20%; }

.InferenceResultListPage-styles-module--organizationDropdown--Sk2fj {
  width: 30%; }

.InferenceResultListPage-styles-module--searchBox--3XECl {
  width: 45%; }

.InferenceResultListPage-styles-module--table--2CjLl thead {
  font-weight: 800; }

.InferenceResultListPage-styles-module--table--2CjLl thead > tr > th:last-child,
.InferenceResultListPage-styles-module--table--2CjLl tbody > tr > td:last-child {
  padding-right: 2rem;
  text-align: right; }

.InferenceResultListPage-styles-module--footer--1s9zO {
  width: 100%;
  text-align: center;
  padding: 1rem; }
