.ContractRow-styles-module--muted--2n1im,
.ContractRow-styles-module--muted--2n1im input {
  color: #bfbfbf; }

.ContractRow-styles-module--title--1hLdt {
  color: white;
  width: 100%; }

.ContractRow-styles-module--twoColumns--1qcdd {
  display: flex;
  width: 100%;
  flex-direction: column; }

.ContractRow-styles-module--rate--3Fqk6 {
  min-width: 12rem; }

.ContractRow-styles-module--grade--2qO7Z {
  min-width: 18rem;
  text-align: left !important; }

.ContractRow-styles-module--enabled--3tEIE {
  padding-left: 1.8rem;
  margin-bottom: 0;
  display: inline-block; }

.ContractRow-styles-module--review-task-row--20sAQ .ContractRow-styles-module--title--1hLdt {
  padding-left: 2rem !important; }
