.InferenceProcessListPage-styles-module--selectRow--2FkIA {
  border-radius: 0.8rem;
  background-color: #333333;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem; }

.InferenceProcessListPage-styles-module--processDropdown--3YUJH {
  width: 45%; }

.InferenceProcessListPage-styles-module--deployStatusDropdown--HeZCE {
  width: 20%; }

.InferenceProcessListPage-styles-module--servableStatusDropdown--1OmQW {
  width: 20%; }

.InferenceProcessListPage-styles-module--table--2SVUX thead {
  font-weight: 800; }

.InferenceProcessListPage-styles-module--table--2SVUX thead > tr > th:last-child,
.InferenceProcessListPage-styles-module--table--2SVUX tbody > tr > td:last-child {
  padding-right: 2rem;
  text-align: right; }

.InferenceProcessListPage-styles-module--footer--2uVi2 {
  width: 100%;
  text-align: center;
  padding: 1rem; }
