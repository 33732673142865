.QualityIndicator-styles-module--container--3jvff {
  width: 100px;
  height: 20px;
  position: relative; }

.QualityIndicator-styles-module--slider--3MOi2 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-image: linear-gradient(to right, #f92400, #ffdc00 33%, #45b562 53%);
  position: absolute; }

.QualityIndicator-styles-module--slider--3MOi2::-webkit-slider-thumb {
  -webkit-appearance: none;
          appearance: none;
  width: 1.2rem;
  height: 1.2rem;
  background: white;
  border-radius: 50%;
  cursor: pointer; }

.QualityIndicator-styles-module--ticks--1LRRC {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute; }

.QualityIndicator-styles-module--tick--2fj91 {
  position: relative;
  justify-content: center;
  background: black;
  width: 0.2rem;
  height: 2rem;
  opacity: 0.2; }
