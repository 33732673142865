.AccordionFieldArray-styles-module--item--17orh {
  overflow: visible !important; }
  .AccordionFieldArray-styles-module--item--17orh:first-of-type:last-of-type {
    border-bottom: 1px solid #333333; }
  .AccordionFieldArray-styles-module--item--17orh, .AccordionFieldArray-styles-module--item--17orh:hover {
    border-color: #333333; }

.AccordionFieldArray-styles-module--labelContainer--3yybr {
  padding: 1rem 1.5rem; }

.AccordionFieldArray-styles-module--title--2jYLC {
  font-weight: 700; }

.AccordionFieldArray-styles-module--remove--9LFjV:disabled {
  opacity: 0.5; }
