.PreviewListItem-styles-module--item-base--3wINr {
  display: block;
  flex: none; }

.PreviewListItem-styles-module--item--3UWFv {
  border: 1px solid transparent;
  cursor: pointer; }
  .PreviewListItem-styles-module--item--3UWFv:hover {
    border-color: #2779ff; }
  .PreviewListItem-styles-module--item--3UWFv.PreviewListItem-styles-module--active--2dTbd {
    border-color: #2779ff; }
  .PreviewListItem-styles-module--item--3UWFv > img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.PreviewListItem-styles-module--placeholder--TXCL5 {
  background: rgba(242, 242, 242, 0.02); }
