.LoadingSheet-Sheet-module--cls1--20Kt6 {
  /*!*/ }

.LoadingSheet-Sheet-module--cls2--iygCL {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 1; }
