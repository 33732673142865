.Table-module--cls1--2RdZC {
  /*!*/ }

.Table-module--cls2--3N2Ul {
  border-collapse: collapse;
  width: 100%; }
  .Table-module--cls2--3N2Ul.Table-module--variant-styled-header--2RPN0 thead > tr th {
    background-color: #333333; }
  .Table-module--cls2--3N2Ul.Table-module--variant-styled-header--2RPN0 thead > tr th:first-child {
    border-top-left-radius: 8px; }
  .Table-module--cls2--3N2Ul.Table-module--variant-styled-header--2RPN0 thead > tr th:last-child {
    border-top-right-radius: 8px; }
  .Table-module--cls2--3N2Ul.Table-module--variant-styled-header--2RPN0 thead > tr > th:first-child,
  .Table-module--cls2--3N2Ul.Table-module--variant-styled-header--2RPN0 tbody > tr > td:first-child {
    padding-left: 2rem; }
  .Table-module--cls2--3N2Ul > thead > tr > th {
    color: white;
    padding: 10px 15px;
    font-weight: inherit; }
  .Table-module--cls2--3N2Ul > tbody > tr > td {
    padding: 10px 15px;
    height: 54px; }
  .Table-module--cls2--3N2Ul > * > tr {
    border-bottom: 1px solid #383838; }
  .Table-module--cls2--3N2Ul > tbody > tr > td:first-child,
  .Table-module--cls2--3N2Ul > thead > tr > th:first-child {
    padding-left: 0;
    text-align: left; }
  .Table-module--cls2--3N2Ul > tbody > tr > td:last-child,
  .Table-module--cls2--3N2Ul > thead > tr > th:last-child {
    padding-right: 0;
    text-align: right; }
  .Table-module--cls2--3N2Ul > tbody > tr:last-of-type {
    border-bottom: 0; }
