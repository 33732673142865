.Lightbox-ImageContainer-module--cls1--222Si {
  /*!*/ }

.Lightbox-ImageContainer-module--cls2--1H8bU {
  width: 100%;
  display: flex;
  max-width: calc(100vw - 44rem);
  flex-direction: column;
  background-color: black;
  overflow: auto; }
  .Lightbox-ImageContainer-module--cls2--1H8bU:only-child {
    overflow: none;
    max-width: calc(100vw - 4rem); }
