.TaskReferenceImagePage-ListItemBody-module--cls1--uAOgo {
  /*!*/ }

.TaskReferenceImagePage-ListItemBody-module--cls2--2HkN2 {
  width: 100%;
  flex: 1; }
  .TaskReferenceImagePage-ListItemBody-module--cls2--2HkN2 > *:first-child,
  .TaskReferenceImagePage-ListItemBody-module--cls2--2HkN2 textarea {
    width: 100%;
    height: 100%;
    flex: 1;
    resize: none; }
