.Lightbox-Dialog-module--cls1--V7Oaw {
  /*!*/ }

.Lightbox-Dialog-module--cls2--3BAz8 {
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  height: 100%;
  max-width: 80vw;
  max-height: 85vh;
  width: calc(100vw - 4rem);
  z-index: 1050;
  transform: translate(-50%, -50%); }
