.Lightbox-Image-module--cls1--d0b96 {
  /*!*/ }

.Lightbox-Image-module--cls2--12cVA {
  position: relative;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
