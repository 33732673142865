.UserWorklistDetailPage-styles-module--breadcrumb---iQ6a {
  margin-bottom: 1rem; }

.UserWorklistDetailPage-styles-module--rowNum--2lchi {
  flex: 1 0 0;
  color: #ffffff; }

.UserWorklistDetailPage-styles-module--taskName--PIbJJ {
  flex: 4 0 0; }

.UserWorklistDetailPage-styles-module--info--ksqvF,
.UserWorklistDetailPage-styles-module--skipped--Dgiut {
  flex: 1 0 0; }

.UserWorklistDetailPage-styles-module--skipped--Dgiut {
  color: #bfbfbf; }

.UserWorklistDetailPage-styles-module--row--2os3N:hover {
  cursor: pointer;
  background-color: #212123; }
