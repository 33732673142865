.ColladaOverlayCine-ThreeJsOverlay-module--cls1--1OTV9 {
  /*!*/ }

.ColladaOverlayCine-ThreeJsOverlay-module--cls2--1rU2b {
  position: absolute;
  opacity: 0.4;
  pointer-events: none;
  top: 0;
  width: 800px;
  height: 600px;
  left: 50%;
  transform: translateX(-50%); }
