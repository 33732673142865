.TaskDefinitionControl-styles-module--container--30wW1 {
  flex: 1; }

.TaskDefinitionControl-styles-module--error--JRbYM {
  display: block;
  font-weight: 700;
  font-style: normal;
  color: #db1c1c;
  margin-top: 0.5em;
  text-align: left; }

.TaskDefinitionControl-styles-module--control--2hm4Y {
  height: auto;
  width: 100%; }
