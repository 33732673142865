.Lightbox-Backdrop-module--cls1--1YaUO {
  /*!*/ }

.Lightbox-Backdrop-module--cls2--3mUqH {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1040;
  background: linear-gradient(rgba(0, 0, 0, 0.7), #1a1a1a); }
