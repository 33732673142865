.prism-module--theme--1x3ul { }
  .prism-module--theme--1x3ul,
  .prism-module--theme--1x3ul code {
    color: #808080;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 2;
         tab-size: 2;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none; }

.prism-module--dark--2hPR3 .token.important,
.prism-module--dark--2hPR3 .token.bold,
.prism-module--light--2Bp_M .token.important,
.prism-module--light--2Bp_M .token.bold {
  font-weight: bold; }

.prism-module--dark--2hPR3 .token.italic,
.prism-module--light--2Bp_M .token.italic {
  font-style: italic; }

.prism-module--dark--2hPR3 .token.entity,
.prism-module--light--2Bp_M .token.entity {
  cursor: help; }

.prism-module--light--2Bp_M {
  color: #333333; }
  .prism-module--light--2Bp_M .token.punctuation {
    color: #333333; }
  .prism-module--light--2Bp_M .token.property,
  .prism-module--light--2Bp_M .token.tag,
  .prism-module--light--2Bp_M .token.boolean,
  .prism-module--light--2Bp_M .token.number,
  .prism-module--light--2Bp_M .token.constant,
  .prism-module--light--2Bp_M .token.important,
  .prism-module--light--2Bp_M .token.keyword,
  .prism-module--light--2Bp_M .token.url,
  .prism-module--light--2Bp_M .token.symbol {
    color: #2779ff; }

.prism-module--dark--2hPR3 .token.punctuation {
  color: #808080; }

.prism-module--dark--2hPR3 .token.property,
.prism-module--dark--2hPR3 .token.tag,
.prism-module--dark--2hPR3 .token.boolean,
.prism-module--dark--2hPR3 .token.number,
.prism-module--dark--2hPR3 .token.constant,
.prism-module--dark--2hPR3 .token.important,
.prism-module--dark--2hPR3 .token.keyword,
.prism-module--dark--2hPR3 .token.symbol {
  color: #50e3c2; }

.prism-module--dark--2hPR3 .token.selector,
.prism-module--dark--2hPR3 .token.attr-name,
.prism-module--dark--2hPR3 .token.string,
.prism-module--dark--2hPR3 .token.char,
.prism-module--dark--2hPR3 .token.url,
.prism-module--dark--2hPR3 .token.builtin {
  color: #f2f2f2; }
