.Lightbox-CloseButton-module--cls1--2FxTQ {
  /*!*/ }

.Lightbox-CloseButton-module--cls2--EThUn {
  position: absolute;
  top: 3rem;
  right: 3rem;
  color: white; }
  .Lightbox-CloseButton-module--cls2--EThUn:hover {
    color: #bfbfbf; }
